<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.joboffers") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <p>
              Für unsere moderne Zahnarztpraxis im Gesundheitszentrum
              Dresden-Prohlis suchen wir für Vollzeit oder Teilzeit:
            </p>
            <ul>
              <li>
                Wir haben derzeit keine offenen Stellen zu besetzen.<br />
                (Stand 03/2023)
              </li>
            </ul>
            <!--
            <ul>
              <li>Zahnarzt (m/w/d)</li>
              <script type="application/ld+json">
                {
                  "@context": "https://schema.org/",
                  "@type": "JobPosting",
                  "title": "Zahnarzt (m/w/d)",
                  "description": "<p>Für unsere moderne Zahnarztpraxis im Gesundheitszentrum Dresden-Prohlis suchen wir für Vollzeit oder Teilzeit: Zahnarzt / Vorbereitungsassitent (m/w/d)</p><p>Möchten Sie ein Teil unseres Teams werden?</p><p>Dann bewerben Sie sich bei uns. Wir freuen uns über die Zusendung Ihrer aussagekräftigen Bewerbung bevorzugt per E-Mail an: jobs@zahnarztpraxis-heindel.de</p>",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Zahnarztpraxis Heindel",
                    "value": "1"
                  },
                  "datePosted": "2020-12-08",
                  "validThrough": "2023-06-30T00:00",
                  "employmentType": ["FULL_TIME", "PART_TIME"],
                  "hiringOrganization": {
                    "@type": "Organization",
                    "name": "Zahnarztpraxis Heindel",
                    "sameAs": "https://www.zahnarztpraxis-heindel.de"
                  },
                  "jobLocation": {
                    "@type": "Place",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "Georg-Palitzsch-Straße 12",
                      "addressLocality": "Dresden",
                      "addressRegion": "Sachsen",
                      "postalCode": "01239",
                      "addressCountry": "DE"
                    }
                  }
                }
              </script>
              <li>
                Abrechnungsassistenz, Zahnmedizinische Verwaltungsassistenz
                (ZMV) (m/w/d)
              </li>
              <script type="application/ld+json">
                {
                  "@context": "https://schema.org/",
                  "@type": "JobPosting",
                  "title": "Abrechnungsassistenz, Zahnmedizinische Verwaltungsassistenz (ZMV) (m/w/d)",
                  "description": "<p>Für unsere moderne Zahnarztpraxis im Gesundheitszentrum Dresden-Prohlis suchen wir für Vollzeit oder Teilzeit: Abrechnungsassistenz, Zahnmedizinische Verwaltungsassistenz (ZMV) (m/w/d)</p><p>Möchten Sie ein Teil unseres Teams werden?</p><p>Dann bewerben Sie sich bei uns. Wir freuen uns über die Zusendung Ihrer aussagekräftigen Bewerbung bevorzugt per E-Mail an: jobs@zahnarztpraxis-heindel.de</p>",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Zahnarztpraxis Heindel",
                    "value": "2"
                  },
                  "datePosted": "2020-12-08",
                  "validThrough": "2021-04-01T00:00",
                  "employmentType": ["FULL_TIME", "PART_TIME"],
                  "hiringOrganization": {
                    "@type": "Organization",
                    "name": "Zahnarztpraxis Heindel",
                    "sameAs": "https://www.zahnarztpraxis-heindel.de"
                  },
                  "jobLocation": {
                    "@type": "Place",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "Georg-Palitzsch-Straße 12",
                      "addressLocality": "Dresden",
                      "addressRegion": "Sachsen",
                      "postalCode": "01239",
                      "addressCountry": "DE"
                    }
                  }
                }
              </script>
              <li>Zahnmedizinische Fachangestellte (ZFA) (m/w/d)</li>
              <script type="application/ld+json">
                {
                  "@context": "https://schema.org/",
                  "@type": "JobPosting",
                  "title": "Zahnmedizinische Fachangestellte (ZFA) (m/w/d)",
                  "description": "<p>Für unsere moderne Zahnarztpraxis im Gesundheitszentrum Dresden-Prohlis suchen wir für Vollzeit oder Teilzeit: Zahnmedizinische Fachangestellte (ZFA) (m/w/d)</p><p>Möchten Sie ein Teil unseres Teams werden?</p><p>Dann bewerben Sie sich bei uns. Wir freuen uns über die Zusendung Ihrer aussagekräftigen Bewerbung bevorzugt per E-Mail an: jobs@zahnarztpraxis-heindel.de</p>",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Zahnarztpraxis Heindel",
                    "value": "3"
                  },
                  "datePosted": "2020-12-08",
                  "validThrough": "2021-04-01T00:00",
                  "employmentType": ["FULL_TIME", "PART_TIME"],
                  "hiringOrganization": {
                    "@type": "Organization",
                    "name": "Zahnarztpraxis Heindel",
                    "sameAs": "https://www.zahnarztpraxis-heindel.de"
                  },
                  "jobLocation": {
                    "@type": "Place",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "Georg-Palitzsch-Straße 12",
                      "addressLocality": "Dresden",
                      "addressRegion": "Sachsen",
                      "postalCode": "01239",
                      "addressCountry": "DE"
                    }
                  }
                }
              </script>
              <li>Zahnmedizinische Prophylaxeassistenz (ZMP) (m/w/d)</li>
              <script type="application/ld+json">
                {
                  "@context": "https://schema.org/",
                  "@type": "JobPosting",
                  "title": "Zahnmedizinische Prophylaxeassistenz (ZMP) (m/w/d)",
                  "description": "<p>Für unsere moderne Zahnarztpraxis im Gesundheitszentrum Dresden-Prohlis suchen wir für Vollzeit oder Teilzeit: Zahnmedizinische Prophylaxeassistenz (ZMP) (m/w/d)</p><p>Möchten Sie ein Teil unseres Teams werden?</p><p>Dann bewerben Sie sich bei uns. Wir freuen uns über die Zusendung Ihrer aussagekräftigen Bewerbung bevorzugt per E-Mail an: jobs@zahnarztpraxis-heindel.de</p>",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Zahnarztpraxis Heindel",
                    "value": "4"
                  },
                  "datePosted": "2020-12-08",
                  "validThrough": "2021-04-01T00:00",
                  "employmentType": ["FULL_TIME", "PART_TIME"],
                  "hiringOrganization": {
                    "@type": "Organization",
                    "name": "Zahnarztpraxis Heindel",
                    "sameAs": "https://www.zahnarztpraxis-heindel.de"
                  },
                  "jobLocation": {
                    "@type": "Place",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "Georg-Palitzsch-Straße 12",
                      "addressLocality": "Dresden",
                      "addressRegion": "Sachsen",
                      "postalCode": "01239",
                      "addressCountry": "DE"
                    }
                  }
                }
              </script>
            </ul>-->
            <!-- <p>Möchten Sie ein Teil unseres Teams werden?</p> -->
            <p>
              Sie sind der Meinung, wir sollten Sie trotzdem kennenlernen und
              möchten Teil unseres Teams werden?
            </p>
            <p>
              Dann bewerben Sie sich bei uns. Wir freuen uns über die Zusendung
              Ihrer aussagekräftigen Bewerbung bevorzugt per E-Mail an:<br /><br /><a
                href="mailto:jobs@zahnarztpraxis-heindel.de?subject=Bewerbung"
                style="color: #000000"
                ><i class="material-icons">email</i>
                jobs@zahnarztpraxis-heindel.de</a
              >
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              For our modern dental practice in the Dresden-Prohlis Health
              Center, we are looking for full-time or part-time:
            </p>
            <ul>
              <li>
                We do not have any vacancies at the moment.<br />
                (Status 03/2023)
              </li>
            </ul>
            <!--<ul>
              <li>Dentist / preparatory assistant (m / f / d)</li>
              <li>
                Accounting assistant, dental administrative assistant (ZMV) (m /
                f / d)
              </li>
              <li>Dental assistant (ZFA) (m / f / d)</li>
              <li>Dental Prophylaxis Assistant (ZMP) (m / f / d)</li>
            </ul>
            -->
            <!--<p>Would you like to be part of our team?</p>-->
            <p>
              You think we should get to know you anyway and would like to
              become part of our team?
            </p>
            <p>
              Then apply to us. We look forward to receiving your detailed
              application, preferably by email to:<br /><br /><a
                href="mailto:jobs@zahnarztpraxis-heindel.de?subject=Bewerbung"
                style="color: #000000"
                ><i class="material-icons">email</i>
                jobs@zahnarztpraxis-heindel.de</a
              >
            </p>
          </div>
          <!-- 

<h3>Mitarbeiter/in für die Rezeption (m/w/d)</h3>
<p>
Vollzeit/Teilzeit
Gerne Hotelfachfrauen/-männer

Wir erwarten:
</p>
<ul>
    <li>Positive Ausstrahlung</li>
    <li>Freundliches und sicheres Auftreten am Telefon und an der Rezeption</li>
    <li>Engagement und Zuverlässigkeit</li>
    <li>Gute Beherrschung der deutschen Sprache in Wort und Schrift</li>
    <li>Organisationstalent</li>
    <li>Wir begrüßen ausdrücklich auch Quereinsteiger</li>
</ul>



<h3>ZMV</h3>
<p>Wir erwarten:</p>
<ul>
    <li>Freundliches Auftreten gegenüber Patienten und Kollegen</li>
    <li>Abgeschlossene Ausbildung zur zahnmedizinischen Verwaltungsassistenz oder zahnmedizinischen Fachangestellten mit Schwerpunkt Abrechnung</li>
    <li>Engagement und Zuverlässigkeit</li>
    <li>Gute Beherrschung der deutschen Sprache in Wort und Schrift</li>
    <li>Organisationstalent</li>
</ul>
<p>Wir bieten:</p>
<ul>
    <li>Langfristige Zusammenarbeit</li>
    <li>Engagiertes und freundliches Team</li>
    <li>Geregelte Arbeitszeiten</li>
    <li>Überdurchschnittliche Bezahlung</li>
</ul>
-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/van-tay-media-Kab_-4M4I74-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.joboffers"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
