<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.forms") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <p>
              Bitte unterstützen Sie unserer Praxisabläufe, indem Sie unser
              Angebot zur digitalen Anamnese nutzen. Dies erfoglt DSGVO-Konform
              bereits vor Ihrem Besuch in unserer Praxis. Das spart Zeit und
              Papier.
            </p>
            <p>
              Wenn Sie weiterhin das Papierformat nutzen möchten, können Sie
              sich hier in Ruhe vorab über die in unserer Praxis eingesetzten
              Formulare informieren und diese ggfs. auch bereits ausdrucken,
              ausfüllen und zum Behandlungstermin mitbringen:
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              Please support our practice processes by using our digital medical
              history service before your visit to our practice. This is done in
              compliance with the German Data Protection Act (DSGVO). It saves
              time and paper.
            </p>
            <p>
              If you still want to use the paper format, here you can inform
              yourself in advance about the forms used in our practice and, if
              you want, print them out, fill them out and bring them with you to
              the treatment appointment:
            </p>
          </div>

          <h3>{{ $t("common.forPreparation") }}</h3>
          <p>
            <md-button class="md-info md-round"
              ><a href="https://gonelly.de/zah" target="nelly">
                <i class="material-icons">edit_document</i
                ><span style="font-weight: bold; color: #fff">{{
                  $t("menu.anamnesis")
                }}</span></a
              >
            </md-button>
          </p>
          <h3>{{ $t("common.forInformation") }}</h3>
          <ul>
            <li>
              <a href="/pdf/Anamnesebogen.pdf"
                ><i class="material-icons">assignment</i> Anmelde- und
                Anamnesebogen zur Dokumentation und Erfassung der
                gesundheitlichen Vorgeschichte</a
              >
            </li>
            <li>
              <a href="/pdf/DZR-Einverstaendniserklaerung.pdf"
                ><i class="material-icons">assignment</i>
                Einverständniserklärung (DZR)</a
              >
            </li>
            <li>
              <a
                href="/pdf/Vorlagen Entbindung Schweigepflich Rö u. Unterlagen.pdf"
                ><i class="material-icons">assignment</i>
                Schweigepflichtentbindung Röntgenbilder / Unterlagen</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage Verhaltensregeln-nach-OP.pdf"
                ><i class="material-icons">assignment</i> Verhaltensregeln nach
                OP</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage Schweigepflichtentbindung Gutachter.pdf"
                ><i class="material-icons">assignment</i>
                Schweigepflichtentbindung Gutachter</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage MKV PZR Bleaching.pdf"
                ><i class="material-icons">assignment</i> Kosteninformation
                Professionellen Zahnreinigung (PZR) / Bleaching</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage MKV Fllg und Inlays.pdf"
                ><i class="material-icons">assignment</i> Mehrkostenvereinbarung
                Füllungsalternativen</a
              >
            </li>

            <li>
              <a href="/pdf/Vorlage MKV Endo.pdf"
                ><i class="material-icons">assignment</i> Mehrkostenvereinbarung
                Endodontie</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage fehlende KVK PAT.pdf"
                ><i class="material-icons">assignment</i> Patientenerklärung
                Zahnärztliche Behandlung bei fehlendem Versicherungsnachweis</a
              >
            </li>
            <li>
              <a
                href="/pdf/Vorlage Einverständniserklärung-Wurzelbehandlung.pdf"
                ><i class="material-icons">assignment</i>
                Einverständniserklärung Wurzelkanalbehandlung</a
              >
            </li>
            <li>
              <a href="/pdf/Vorlage Einv Beh bei Minderjährigen.pdf"
                ><i class="material-icons">assignment</i>
                Einverständniserklärung Behandlung minderjähriger Patienten</a
              >
            </li>
            <!-- <li><a href="/pdf/Vorlage DSGVO.pdf">Datenschutzrechtliche Einwilligungserklärung</a></li>-->

            <li>
              <a
                href="/pdf/Vorlage Ablehnung-einer-Behandlung-gegen-ärztlichen-Rat.pdf"
                ><i class="material-icons">assignment</i> Ablehnung der
                Behandlung gegen ärztlichen Rat</a
              >
            </li>
            <!-- <li><a href="/pdf/Vorlage A-Bogen.pdf">Patientenerhebungsbogen</a></li> -->
            <!-- <li><a href="/pdf/HKP-Planungszettel.pdf">HKP-Planungszettel</a></li> -->
            <li>
              <a
                href="/pdf/Einverständniserklärung-operative-Weisheitszahnentfernung.pdf"
                ><i class="material-icons">assignment</i> Einverständnis
                Operative Weisheitszahnentfernung im Ober- und / oder
                Unterkiefer</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/ron-dyar-V29UWcALNko-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.forms"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
