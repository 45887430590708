<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.recommendations") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <h3>Wir möchten, dass Sie mit uns zufrieden sind!</h3>
            <p>
              Verschaffen Sie Ihrer Meinung Luft und bewerten Sie uns. Mit Ihrer
              Bewertung helfen Sie nicht nur anderen Menschen eine Entscheidung
              zu treffen, sondern auch dem gesamten Team unserer Zahnarztpraxis,
              den eigenen Service zu verbessern. Unser Ziel ist es, tagtäglich
              den besten Eindruck zu hinterlassen. Deshalb freuen wir uns auf
              Ihr Feedback und bedanken uns für Ihre wertvolle Unterstützung.
            </p>
            <p>
              Sind Sie mit dem Behandlungsverlauf und vor allem dem Ergebnis
              zufrieden? Sagen Sie es allen weiter. Sollte Ihnen etwas
              missfallen haben, können Sie es uns gern persönlich sagen. Wir
              nehmen Ihre Kritik sehr ernst.
              <br />
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <h3>We want you to be happy with us!</h3>
            <p>
              Give us your opinion and rate us. With your evaluation you not
              only help other people to make a decision, but also the entire
              team in our dental practice to improve their own service. Our goal
              is to make the best impression every day. We therefore look
              forward to your feedback and thank you for your valuable support.
            </p>
            <p>
              Are you satisfied with the course of treatment and, above all, the
              result? Spread the word to everyone. If you dislike something, you
              are welcome to tell us personally. We take your criticism very
              seriously.
              <br />
            </p>
          </div>

          <router-link :to="$i18nRoute({ name: 'feedback' })">
            <md-button class="md-success">
              <md-icon>forum</md-icon> {{ $t("common.rateUs") }}
            </md-button>
          </router-link>

          <div v-if="$i18n.locale == 'de'">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <h3>Glücklich?</h3>

                <p>
                  Wir sind glücklich, dass Sie glücklich sind. Wenn Sie nicht
                  wissen, wie Sie Ihre neugefundene Freude ausdrücken sollen,
                  hätten wir da ein paar Vorschläge für Sie:
                </p>

                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >record_voice_over</i
                  >
                  <b>Erzählen</b> Sie es Ihren Freunden und Familie
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >share</i
                  >
                  <b>Teilen</b> Sie Ihr Erlebnis indem Sie eine Bewertung auf
                  Jameda,
                  <a href="https://www.facebook.com/zahnarztpraxis.heindel"
                    >Facebook</a
                  >,
                  <a href="https://g.page/zahnarzt-dresden-prohlis/review?np"
                    >Google</a
                  >
                  oder unserer
                  <router-link :to="$i18nRoute({ name: 'feedback' })"
                    >Website</router-link
                  >
                  schreiben
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >language</i
                  >
                  <b>Verbinden</b> Sie sich mit uns auf unserer
                  <a href="https://www.zahnarztpraxis-heindel.de/?ref=feedback"
                    >Website</a
                  >,
                  <a
                    href="https://facebook.com/zahnarztpraxis.heindel?ref=website"
                    >Facebook</a
                  >
                  oder
                  <a href="https://instagram.com/zahnarzt_dresden?ref=website"
                    >Instagram</a
                  >
                </p>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <h3>Nicht glücklich?</h3>

                <p>
                  Unsere freundlichen Mitarbeiter werden hart daran arbeiten,
                  Ihnen wieder ein Lächeln ins Gesicht zu zaubern! So können wir
                  in Kontakt treten:
                </p>

                <p style="font-weight: bold">
                  <a href="tel:+493512847014" style="color: #ff007f"
                    ><i class="material-icons">phone</i> 0351 / 284 7014</a
                  >
                </p>
                <p>
                  <a href="https://wa.me/4915678576056" style="color: #000000"
                    ><img
                      src="../assets/img/wa.png"
                      width="24"
                      style="width: 24px"
                    />
                    WhatsApp</a
                  >
                  <a
                    href="https://m.me/zahnarztpraxis.heindel"
                    style="color: #000000"
                    ><img
                      src="../assets/img/fb.png"
                      width="24"
                      style="width: 24px"
                    />
                    Messenger</a
                  >
                </p>

                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >email</i
                  >
                  <a
                    href="mailto:patientenfeedback@zahnarztpraxis-heindel.de"
                    style="color: #000000"
                    >patientenfeedback@zahnarztpraxis-heindel.de</a
                  >
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >forum</i
                  >
                  <router-link :to="$i18nRoute({ name: 'feedback' })"
                    >zahnarztpraxis-heindel.de/patientenfeedback</router-link
                  >
                </p>
              </div>
            </div>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <h3>Happy?</h3>

                <p>
                  We are happy that you are happy. If you don't know how to
                  express your newfound joy, we have a few suggestions for you:
                </p>

                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >record_voice_over</i
                  >
                  <b>Tell</b> your friends and family
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >share</i
                  >
                  <b>Share</b> your experience by writing a review on Jameda,
                  <a href="https://www.facebook.com/zahnarztpraxis.heindel"
                    >Facebook</a
                  >,
                  <a href="https://g.page/zahnarzt-dresden-prohlis/review?np"
                    >Google</a
                  >
                  or our
                  <router-link :to="$i18nRoute({ name: 'feedback' })"
                    >Website</router-link
                  >
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >language</i
                  >
                  <b>Connect</b> with us on our
                  <a href="https://www.zahnarztpraxis-heindel.de/?ref=feedback"
                    >website</a
                  >,
                  <a
                    href="https://facebook.com/zahnarztpraxis.heindel?ref=website"
                    >Facebook</a
                  >
                  or
                  <a href="https://instagram.com/zahnarzt_dresden?ref=website"
                    >Instagram</a
                  >
                </p>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <h3>Not happy?</h3>

                <p>
                  Our friendly staff will work hard to put a smile on your face
                  again! This is how we can get in touch:
                </p>

                <p style="font-weight: bold">
                  <a href="tel:+493512847014" style="color: #ff007f"
                    ><i class="material-icons">phone</i> 0351 / 284 7014</a
                  >
                </p>
                <p>
                  <a href="https://wa.me/4915678576056" style="color: #000000"
                    ><img
                      src="../assets/img/wa.png"
                      width="24"
                      style="width: 24px"
                    />
                    WhatsApp</a
                  >
                  <a
                    href="https://m.me/zahnarztpraxis.heindel"
                    style="color: #000000"
                    ><img
                      src="../assets/img/fb.png"
                      width="24"
                      style="width: 24px"
                    />
                    Messenger</a
                  >
                </p>

                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >email</i
                  >
                  <a
                    href="mailto:patientenfeedback@zahnarztpraxis-heindel.de"
                    style="color: #000000"
                    >patientenfeedback@zahnarztpraxis-heindel.de</a
                  >
                </p>
                <p>
                  <i class="material-icons" style="position: relative; top: 6px"
                    >forum</i
                  >
                  <router-link :to="$i18nRoute({ name: 'feedback' })"
                    >zahnarztpraxis-heindel.de/patientenfeedback</router-link
                  >
                </p>
              </div>
            </div>
          </div>

          <div v-if="$i18n.locale == 'de'">
            <p className="description" style="paddingtop: 40px">
              PS: Sie können das Formular auch unterwegs vom Handy aus
              ausfüllen. Scannen Sie dazu einfach den folgenden Code:
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p className="description" style="paddingtop: 40px">
              PS: You can also fill out the form from your mobile phone when you
              are out and about. To do this, simply scan the following code:
            </p>
          </div>

          <router-link :to="$i18nRoute({ name: 'feedback' })"
            ><img
              :src="qr"
              alt="QR Code für Formular für Patientenfeedback"
              width="140"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      qr: require("@/assets/img/zaph/qr-patientenfeedback.png"),
      image: require("@/assets/img/zaph/anton-YTYlNNl7bo0-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.recommendations"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
