// prettier-ignore
<!-- prettier-ignore -->
<template>
  <!-- div itemscope itemtype="https://schema.org/FAQPage" -->
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.faq") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">

            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">Nehmen Sie noch neue Patienten an?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ja. Durch Praxisvergrößerung, verlängerte Öffnungszeiten und
                    Änderungen in der Praxisorganisation haben wir zusätzliche
                    Kapazitäten geschaffen und können derzeit noch neue
                    Patienten aufnehmen. Wir freuen uns auf Ihren Besuch!
                  </p>
                  <p>
                    Weitere Informationen zur Terminbuchung finden Sie hier:
                    <router-link :to="$i18nRoute({ name: 'bookOnline' })"
                      ><i class="material-icons">book_online</i
                      >{{ $t("menu.bookAppointment") }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich habe mein Bonusheft verloren, können Sie mir ein Duplikat
                ausstellen?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ja, wir können auf die Daten der letzten 10 Jahre in unserer
                    Praxis bzw. unseren beiden Vorgängerpraxen zugreifen und
                    anhand dieser Informationen das Bonusheft neu ausstellen.
                  </p>
                  <p>
                    Bitte vereinbaren Sie dazu einen Termin zur Abholung, da wir
                    diese Anfragen gesammelt bearbeiten um die Praxisabläufe zu
                    optimieren. Wir können die Unterlagen leider nicht spontan
                    aushändigen.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">Wie lange speichern Sie meine Daten?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ihre Daten werden aufgrund von gesetzlichen Anforderungen
                    zehn Jahre lang aufbewahrt. Das betrifft: Patientendaten,
                    Behandlungsunterlagen, Aufzeichnungen über zahnärztliche
                    Behandlungen, Röntgenaufnahmen und Aufzeichnungen über
                    Röntgenuntersuchungen.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich habe eine verschlüsselte Nachricht von Ihnen erhalten. Wie
                kann ich diese öffnen?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Um die Nachricht zu lesen, klicken Sie auf den blauen Knopf
                    innerhalb der Benachrichtigungs-E-Mail. Danach fordern Sie
                    einen Einmalcode an, so ähnlich wie bei Ihrem
                    Online-Banking. Eine ausführliche Anleitung finden Sie bei
                    Microsoft unter:
                    <a
                      href="https://support.microsoft.com/de-de/office/wie-%C3%B6ffne-ich-eine-gesch%C3%BCtzte-nachricht-1157a286-8ecc-4b1e-ac43-2a608fbf3098"
                      target="_blank"
                      >Wie öffne ich eine geschützte Nachricht?</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich habe eine Frage zu meiner Rechnung. An wen kann ich mich
                wenden?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Wir arbeiten bei der Abrechnung mit größtmöglicher Sorgfalt,
                    dennoch kann es vorkommen, dass einmal ein Fehler geschieht
                    oder einfach nur eine Position auf der Rechnung unklar ist.
                  </p>
                  <p>
                    Wenn Sie die Rechnung per Post via DZR erhalten haben,
                    finden Sie oben rechts auf dem Briefkopf alle notwendigen
                    Informationen und Kontaktdaten für Rückfragen. Finanzielle
                    Fragen, wie z.B. Vereinbarung einer Ratenzahlung, klären Sie
                    bitte direkt mit dem DZR unter den dort angegeben Daten.
                  </p>
                  <p>
                    Inhaltliche Fragen zur Rechnung beantworten wir Ihnen
                    selbstverständlich auch direkt und persönlich. Bitte haben
                    Sie Verständnis dafür, dass wir dies nicht spontan am
                    Empfang machen können. Wenn mögich, schildern Sie Ihr
                    Anliegen bitte kurz schriftlich oder vereinbaren Sie einen
                    Termin für die persönliche Durchsprache vor Ort.
                  </p>
                  <p>
                    Im Zweifel können Sie sich auch an die
                    <a
                      href="https://www.zahnaerzte-in-sachsen.de/patienten/patientenberatung/"
                      >Patientenberatung der Landeszahnärztekammer Sachsen</a
                    >
                    wenden, welche Sie unabhängig von Ihrer Krankenkasse
                    vertraulich und kostenfrei unterstützt.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Was mache ich, wenn ein anderer Arzt meine Unterlagen benötigt?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Sie füllen beim anfordernden Arzt eine entsprechende
                    Vollmacht aus. Dieser wird sich dann mit der konkreten
                    Nachfrage bei uns melden und über eine verschlüsselte E-Mail
                    alle notwendigen Unterlagen direkt von uns erhalten.
                  </p>
                  <p>
                    Selbstverständlich können Sie die Unterlagen auch persönlich
                    abholen. Bitte vereinbaren Sie dazu einen Termin zur
                    Abholung, da wir diese Anfragen gesammelt bearbeiten um die
                    Praxisabläufe zu optimieren. Wir können die Unterlagen
                    leider nicht spontan aushändigen.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Führen Sie auch Behandlungen unter Vollnarkose durch?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text"><p>Nein.</p></div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">Kann ich bei Ihnen auch in bar bezahlen?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text"><p>Nein.</p></div>
              </div>
            </div>
            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich habe eine private Zusatzversicherung. Wie ist der Ablauf?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Wir erstellen für Sie einen Behandlungsplan, welchen Sie bei
                    Ihrer Versicherung einreichen. Nach Erhalt des Bescheides
                    melden Sie sich bitte bei uns, um einen Termin für die
                    Fortsetzung Ihrer Behandlung zu vereinbaren.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich spreche kein Deutsch. Können Sie mich trotzdem behandeln?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ja, selbstverständlich! Wir müssen nur sicherstellen, dass
                    wir trotzdem miteinander kommunizieren können.
                  </p>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link :to="$i18nRoute({ name: 'communication' })"
                        >Behandlung von Patienten, die kein Deutsch
                        sprechen</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Ich habe einen Termin, warum muss ich trotzdem warten?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Unsere Zahnarztpraxis arbeitet mit einem sogenannten
                    Timeboxing-Modell, bei dem für geplante Behandlungen feste
                    Zeiten, basierend auf Erfahrungswerten, eingeplant werden.
                    Wird z.B. bei einem Kontrolltermin ein Behandlungsbedarf
                    festgestellt, so wird für diesen ein neuer Termin
                    vereinbart, außer es lässt sich noch im bereits verfügbaren
                    Zeitfenster mit unterbringen.
                  </p>
                  <p>
                    So gewährleisten wir, dass niemand unnötig lang warten muss.
                  </p>
                  <p>
                    Dennoch kann es immer zu ungeplanten Komplikationen bei der
                    Behandlung vor Ihnen kommen, wodurch sich Ihr Termin dann
                    etwas verschiebt.
                  </p>
                  <p>
                    Ebenso müssen wir Patienten mit akkuten Schmerzen die
                    Möglichkeit geben, auch ohne einen Wochen im Voraus
                    geplanten Termin behandelt zu werden, eine sogenannte
                    Notfallbehandlung. Dafür haben wir optimierte Zeitfenster,
                    aber auch hier kann es passieren, dass eine ungeplante
                    Schmerzbehandlung bei Ihrem Vorgänger den Beginn Ihrer
                    Behandlung verzögert.
                  </p>
                  <p>
                    Sobald solche Verzögerungen durch uns abschätzbar werden,
                    werden wir Sie ansprechen und die weiteren Optionen
                    besprechen. (voraussichtliche Wartezeit, ggfs.
                    Terminverschiebung wenn Sie nicht länger warten können,
                    etc.)
                  </p>
                  <p>
                    Generell empfehlen wir Ihnen, ca. 5-10 Minuten Puffer vor
                    der Behandlung einzuplanen (Ausfüllen des Anamnesebogens,
                    etc.) und wenn möglich nach hinten raus einen Puffer von 30
                    Minuten einzuplanen.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">Zahnrettungsbox</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ausgeschlagene Milchzähne werden nicht wieder eingesetzt,
                    bleibende Zähne können aber wieder anwachsen, wenn die
                    Zellen der Wurzelhaut überleben. Eine Zahnrettungsbox kann
                    helfen. Fragen Sie dazu in Ihrer Apotheke z.B. nach
                    "DENTOSAFE".
                  </p>
                  <!-- https://www.test.de/Zahnschutz-beim-Sport-Schutz-mit-Biss-1102012-1102106/ -->
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">Führen Sie auch Hausbesuche durch?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Derzeit können wir aus terminlichen Gründen leider nur in
                    Ausnahemfällen Hausbesuche durchführen. Sollte es mit einer
                    Terminvergabe bei uns nicht passen, können Sie sich über
                    <a href="tel:116117" style="color: #ff007f"
                      ><i class="material-icons">phone</i>116 117</a
                    >
                    einen Zahnarzt vermitteln lassen, der Hausbesuche
                    durchführt.
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Privatpatienten: Meine Krankenkasse hat mit mir einen festen
                Faktor im Vertrag vereinbart. Wieso steht auf Ihrer Rechnung ein
                anderer Faktor?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Für die Vergütung zahnärztlicher Behandlungen gibt es in
                    Deutschland zwei verschiedene Gebührenverzeichnisse (<span
                      >BEMA<md-tooltip md-direction="top"
                        >Bewertungsmaßstab für zahnärztliche
                        Leistungen</md-tooltip
                      ></span
                    >
                    und
                    <span
                      >GOZ<md-tooltip md-direction="top"
                        >Gebührenordnung für Zahnärzte</md-tooltip
                      ></span
                    >), mit Preisen, die für alle Zahnärzte einheitlich gelten.
                    Über den Faktor werden auf Grundlage dieser beiden Kataloge
                    die individuellen Zeitaufwände und ggfs. besondere
                    Schwierigkeiten bei der Behandlung berücksichtigt. Es kann
                    deshalb vorkommen, dass für Ihre Behandlung ein niedrigerer
                    oder einen höherer Faktor angewendet werden muss. Ihre
                    Krankenkasse erstattet dann nur bis zu dem mit Ihnen
                    vereinbarten Faktor, auch wenn ein höherer Faktor notwendig
                    wurde.
                  </p>
                </div>
              </div>
            </div>
            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">
                Privatpatienten: Meine Krankenkasse hat eine Liste mit
                Vertragsärzten. Ich möchte mich aber gern bei Ihnen behandeln
                lassen!
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Nach aktueller Rechtssprechung kann die Krankenkasse Sie
                    nicht zu einem Arztwechsel zwingen. (vgl. OLG Dresden,
                    Urteil vom 9.10.2020 – 14 U 807/20, siehe auch Artikel der
                    Kassenzahnärztlichen Vereinbung BW zu
                    <a
                      href="https://news-portal.kzvbw.de/2021/zahnarztpraxis-klagt-gegen-private-krankenversicherung-wettbewerbswidrige-beeinflussung-der-freien-arztwahl/"
                      >wettbewerbswidriger Beeinflussung der freien Arztwahl</a
                    >)
                  </p>
                </div>
              </div>
            </div>

            <div
               itemscope
              itemtype="https://schema.org/Question"
              itemprop="mainEntity"
            >
              <h3 itemprop="name">Wofür steht die Abkürzung D.D.S.?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>D.D.S. steht für: Doctor of dental surgery</p>
                  <!--<p>Das ist der internationale Titel für studierte Zahnärzte.</p>-->
                </div>
              </div>
            </div>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">Are you still accepting new patients?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Yes. Through practice expansion, extended opening hours and
                    changes in practice organization, we have created additional
                    capacity and are currently still able to admit new patients.
                    We look forward to your visit!
                  </p>
                  <p>
                    You can find further information on booking appointments
                    here:
                    <router-link :to="$i18nRoute({ name: 'bookOnline' })"
                      ><i class="material-icons">book_online</i
                      >{{ $t("menu.bookAppointment") }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                I have lost my bonus booklet, can you issue me a duplicate?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Yes, we can access the data of the last 10 years in our
                    practice or our two previous practices and use this
                    information to reissue the bonus booklet.
                  </p>
                  <p>
                    Please make an appointment to pick it up, as we process
                    these requests collectively in order to optimize the
                    practice processes. Unfortunately, we cannot hand out the
                    documents spontaneously.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">How long do you store my data?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Your data will be stored for ten years due to legal
                    requirements. This concerns: patient data, treatment
                    records, records of dental treatments, radiographs and
                    records of X-ray examinations.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                I have a question about my invoice. Who can I contact?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    We work with the greatest possible care when billing, but it
                    is still possible that an error may occur or simply an item
                    on the invoice is unclear.
                  </p>
                  <p>
                    If you have received the invoice by post via DZR, you will
                    find all the necessary information and contact details for
                    queries in the top right-hand corner of the letterhead.
                    Financial questions, e.g. arranging payment by installments,
                    should be clarified directly with the DZR using the
                    information provided there.
                  </p>
                  <p>
                    We will of course also answer any questions regarding the
                    content of the invoice directly and personally. Please
                    understand that we cannot do this spontaneously at
                    reception. If possible, please describe your request briefly
                    in writing or make an appointment for a personal discussion
                    on site.
                  </p>
                  <p>
                    In case of doubt, you can also contact the
                    <a
                      href="https://www.zahnaerzte-in-sachsen.de/patienten/patientenberatung/"
                      >patient advisory service of the Saxony Dental
                      Association</a
                    >, which will support you confidentially and free of charge,
                    regardless of your health insurance company.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                What do I do if another doctor needs my records?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    You fill out an appropriate power of attorney with the
                    requesting physician. He will then contact us with the
                    specific request and will receive all necessary documents
                    directly from us via an encrypted e-mail.
                  </p>
                  <p>
                    Of course you can also pick up the documents personally.
                    Please make an appointment to pick up the documents, as we
                    process these requests collectively in order to optimize the
                    practice processes. Unfortunately, we cannot hand over the
                    documents spontaneously.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                Do you also carry out treatments under general anesthesia?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>No.</p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">Can I pay you in cash?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>No.</p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                I have a private supplementary insurance. What is the procedure?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    We draw up a treatment plan for you, which you submit to
                    your insurance company. After receiving the decision, please
                    contact us to make an appointment for the continuation of
                    your treatment.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                I do not speak German. Can you treat me anyway?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Yes, of course! We just have to make sure that we can
                    communicate with each other.
                  </p>
                  <p>{{ $t("common.learnMore") }}</p>
                  <ul>
                    <li>
                      <router-link
                        :to="$i18nRoute({ name: 'communication' })"
                        >{{ $t("menu.communication") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                I have an appointment, why do I have to wait anyway?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Our dental practice works with a so-called time-boxing
                    model, in which fixed times are scheduled for planned
                    treatments based on experience. If, for example, a need for
                    treatment is identified during a check-up appointment, a new
                    appointment is made for this, unless it can still be
                    accommodated in the time window already available.
                  </p>
                  <p>
                    In this way we ensure that nobody has to wait unnecessarily
                    long.
                  </p>
                  <p>
                    Nevertheless, there can always be unplanned complications
                    during the treatment before you, which can cause your
                    appointment to be postponed somewhat.
                  </p>
                  <p>
                    We also have to give patients with acute pain the
                    opportunity to be treated without an appointment planned
                    weeks in advance, a so-called emergency treatment. We have
                    optimized time windows for this, but even here it can happen
                    that an unplanned pain treatment by your predecessor delays
                    the start of your treatment.
                  </p>
                  <p>
                    As soon as such delays can be estimated by us, we will
                    contact you and discuss further options. (estimated waiting
                    time, possibly postponement of your appointment if you
                    cannot wait any longer, etc.)
                  </p>
                  <p>
                    In general, we recommend that you allow for a buffer of
                    about 5-10 minutes before the treatment (filling out the
                    medical history sheet, etc.) and if possible, a buffer of 30
                    minutes at the back.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">Tooth Rescue Box</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Milk teeth that have been knocked out are not re-inserted,
                    but permanent teeth can grow back if the cells of the
                    periodontal membrane survive. A tooth rescue box can help.
                    Ask for "DENTOSAFE" in your pharmacy, for example.
                  </p>
                  <!-- https://www.test.de/Zahnschutz-beim-Sport-Schutz-mit-Biss-1102012-1102106/ -->
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">Do you also make house calls?</h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Unfortunately, at present we can only make house calls in
                    exceptional cases due to scheduling reasons. If you are
                    unable to make an appointment with us, you can call
                    <a href="tel:116117" style="color: #ff007f"
                      ><i class="material-icons">phone</i>116 117</a
                    >
                    to arrange for a dentist to make house calls.
                  </p>
                </div>
              </div>
            </div>

            <div
              itemscope itemtype="https://schema.org/Question"
            >
              <h3 itemprop="name">
                What does the abbreviation D.D.S. stand for?
              </h3>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>D.D.S. stands for: doctor of dental surgery</p>
                  <!--<p>Das ist der internationale Titel für studierte Zahnärzte.</p>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- /div -->

</template>

<script>
/* eslint-disable */
import Mixins from "@/plugins/basicMixins";





export default {
  
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/ahmed-hindawi-bjB2mGI8PtI-unsplash.jpg"),    
    };
  },
  metaInfo() {

    var faqDE = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity":
        [{
          "@type": "Question",
          "name": "Nehmen Sie noch neue Patienten an?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>Ja. Durch Praxisvergrößerung, verlängerte Öffnungszeiten und Änderungen in der Praxisorganisation haben wir zusätzliche Kapazitäten geschaffen und können derzeit noch neue Patienten aufnehmen. Wir freuen uns auf Ihren Besuch!</p><p>Weitere Informationen zur Terminbuchung finden Sie hier: <a href="https://www.zahnarztpraxis-heinde.de/de/praxis/termin-buchen">Termin buchen</a></p>'
          }
        }, {
          "@type": "Question",
          "name": "Ich habe mein Bonusheft verloren, können Sie mir ein Duplikat ausstellen?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Ja, wir können auf die Daten der letzten 10 Jahre in unserer Praxis bzw. unseren beiden Vorgängerpraxen zugreifen und anhand dieser Informationen das Bonusheft neu ausstellen.</p><p>Bitte vereinbaren Sie dazu einen Termin zur Abholung, da wir diese Anfragen gesammelt bearbeiten um die Praxisabläufe zu optimieren. Wir können die Unterlagen leider nicht spontan aushändigen.</p>"
          }
        }, {
          "@type": "Question",
          "name": "Wie lange speichern Sie meine Daten?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ihre Daten werden aufgrund von gesetzlichen Anforderungen zehn Jahre lang aufbewahrt. Das betrifft: Patientendaten, Behandlungsunterlagen, Aufzeichnungen über zahnärztliche Behandlungen, Röntgenaufnahmen und Aufzeichnungen über Röntgenuntersuchungen."
          }
        }, {
          "@type": "Question",
          "name": "Ich habe eine verschlüsselte Nachricht von Ihnen erhalten. Wie kann ich diese öffnen?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'Um die Nachricht zu lesen, klicken Sie auf den blauen Knopf innerhalb der Benachrichtigungs-E-Mail. Danach fordern Sie einen Einmalcode an, so ähnlich wie bei Ihrem Online-Banking. Eine ausführliche Anleitung finden Sie bei Microsoft unter: <a href="https://support.microsoft.com/de-de/office/wie-öffne-ich-eine-geschützte-nachricht-1157a286-8ecc-4b1e-ac43-2a608fbf3098">Wie öffne ich eine geschützte Nachricht?</a>'
          }
        }, {
          "@type": "Question",
          "name": "Ich habe eine Frage zu meiner Rechnung. An wen kann ich mich wenden?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": ' <p>Wir arbeiten bei der Abrechnung mit größtmöglicher Sorgfalt, dennoch kann es vorkommen, dass einmal ein Fehler geschieht oder einfach nur eine Position auf der Rechnung unklar ist.</p>'
              +'<p>Wenn Sie die Rechnung per Post via DZR erhalten haben, finden Sie oben rechts auf dem Briefkopf alle notwendigen Informationen und Kontaktdaten für Rückfragen. Finanzielle Fragen, wie z.B. Vereinbarung einer Ratenzahlung, klären Sie bitte direkt mit dem DZR unter den dort angegeben Daten.</p>'
              +'<p>Inhaltliche Fragen zur Rechnung beantworten wir Ihnen selbstverständlich auch direkt und persönlich. Bitte haben Sie Verständnis dafür, dass wir dies nicht spontan am Empfang machen können. Wenn mögich, schildern Sie Ihr Anliegen bitte kurz schriftlich oder vereinbaren Sie einen Termin für die persönliche Durchsprache vor Ort.</p>'
              +'<p>Im Zweifel können Sie sich auch an die<a href="https://www.zahnaerzte-in-sachsen.de/patienten/patientenberatung/">Patientenberatung der Landeszahnärztekammer Sachsen</a> wenden, welche Sie unabhängig von Ihrer Krankenkasse vertraulich und kostenfrei unterstützt.</p>'
          }
        }, {
          "@type": "Question",
          "name": "Was mache ich, wenn ein anderer Arzt meine Unterlagen benötigt?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>Sie füllen beim anfordernden Arzt eine entsprechende Vollmacht aus. Dieser wird sich dann mit der konkreten Nachfrage bei uns melden und über eine verschlüsselte E-Mail alle notwendigen Unterlagen direkt von uns erhalten.</p>'
              +'<p>Selbstverständlich können Sie die Unterlagen auch persönlich abholen. Bitte vereinbaren Sie dazu einen Termin zur Abholung, da wir diese Anfragen gesammelt bearbeiten um die Praxisabläufe zu optimieren. Wir können die Unterlagen leider nicht spontan aushändigen.</p>'
          }
        }, {
          "@type": "Question",
          "name": "Führen Sie auch Behandlungen unter Vollnarkose durch?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Nein."
          }
        }, {
          "@type": "Question",
          "name": "Ich habe eine private Zusatzversicherung. Wie ist der Ablauf?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Wir erstellen für Sie einen Behandlungsplan, welchen Sie bei Ihrer Versicherung einreichen. Nach Erhalt des Bescheides melden Sie sich bitte bei uns, um einen Termin für die Fortsetzung Ihrer Behandlung zu vereinbaren."
          }
        }, {
          "@type": "Question",
          "name": "Ich spreche kein Deutsch. Können Sie mich trotzdem behandeln?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>Ja, selbstverständlich! Wir müssen nur sicherstellen, dass wir trotzdem miteinander kommunizieren können.</p><p>Mehr erfahren: <a href="https://www.zahnarztpraxis-heindel.de/de/praxis/kommunikation">Behandlung von Patienten, die kein Deutsch sprechen</a></p>'
          }
        }, {
          "@type": "Question",
          "name": "Ich habe einen Termin, warum muss ich trotzdem warten?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Unsere Zahnarztpraxis arbeitet mit einem sogenannten Timeboxing-Modell, bei dem für geplante Behandlungen feste Zeiten, basierend auf Erfahrungswerten, eingeplant werden. Wird z.B. bei einem Kontrolltermin ein Behandlungsbedarf festgestellt, so wird für diesen ein neuer Termin vereinbart, außer es lässt sich noch im bereits verfügbaren Zeitfenster mit unterbringen.</p>"
            +"<p>So gewährleisten wir, dass niemand unnötig lang warten muss.</p>"
            +"<p>Dennoch kann es immer zu ungeplanten Komplikationen bei der Behandlung vor Ihnen kommen, wodurch sich Ihr Termin dann etwas verschiebt.</p>"
            +"<p>Ebenso müssen wir Patienten mit akkuten Schmerzen die Möglichkeit geben, auch ohne einen Wochen im Voraus geplanten Termin behandelt zu werden, eine sogenannte Notfallbehandlung. Dafür haben wir optimierte Zeitfenster, aber auch hier kann es passieren, dass eine ungeplante Schmerzbehandlung bei Ihrem Vorgänger den Beginn Ihrer Behandlung verzögert.</p>"
            +"<p>Sobald solche Verzögerungen durch uns abschätzbar werden, werden wir Sie ansprechen und die weiteren Optionen besprechen. (voraussichtliche Wartezeit, ggfs. Terminverschiebung wenn Sie nicht länger warten können, etc.)</p>"
            +"<p>Generell empfehlen wir Ihnen, ca. 5-10 Minuten Puffer vor der Behandlung einzuplanen (Ausfüllen des Anamnesebogens, etc.) und wenn möglich nach hinten raus einen Puffer von 30 Minuten einzuplanen.</p>"           
          }
        }, {
          "@type": "Question",
          "name": "Zahnrettungsbox",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ausgeschlagene Milchzähne werden nicht wieder eingesetzt, bleibende Zähne können aber wieder anwachsen, wenn die Zellen der Wurzelhaut überleben. Eine Zahnrettungsbox kann helfen. Fragen Sie dazu in Ihrer Apotheke z.B. nach &quot;DENTOSAFE&quot;."
          }
        }, {
          "@type": "Question",
          "name": "Führen Sie auch Hausbesuche durch?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'Derzeit können wir aus terminlichen Gründen leider nur in Ausnahemfällen Hausbesuche durchführen. Sollte es mit einer Terminvergabe bei uns nicht passen, können Sie sich über <a href="tel:116117" style="color: #ff007f" >116 117</a> einen Zahnarzt vermitteln lassen, der Hausbesuche durchführt.'
          }
        }, {
          "@type": "Question",
          "name": "Privatpatienten: Meine Krankenkasse hat mit mir einen festen Faktor im Vertrag vereinbart. Wieso steht auf Ihrer Rechnung ein anderer Faktor?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Für die Vergütung zahnärztlicher Behandlungen gibt es in Deutschland zwei verschiedene Gebührenverzeichnisse (BEMA und GOZ), mit Preisen, die für alle Zahnärzte einheitlich gelten. Über den Faktor werden auf Grundlage dieser beiden Kataloge die individuellen Zeitaufwände und ggfs. besondere Schwierigkeiten bei der Behandlung berücksichtigt. Es kann deshalb vorkommen, dass für Ihre Behandlung ein niedrigerer oder einen höherer Faktor angewendet werden muss. Ihre Krankenkasse erstattet dann nur bis zu dem mit Ihnen vereinbarten Faktor, auch wenn ein höherer Faktor notwendig wurde."
          }
        }, {
          "@type": "Question",
          "name": "Privatpatienten: Meine Krankenkasse hat eine Liste mit Vertragsärzten. Ich möchte mich aber gern bei Ihnen behandeln lassen!",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'Nach aktueller Rechtssprechung kann die Krankenkasse Sie nicht zu einem Arztwechsel zwingen. (vgl. OLG Dresden, Urteil vom 9.10.2020 – 14 U 807/20, siehe auch Artikel der Kassenzahnärztlichen Vereinbung BW zu <a href="https://news-portal.kzvbw.de/2021/zahnarztpraxis-klagt-gegen-private-krankenversicherung-wettbewerbswidrige-beeinflussung-der-freien-arztwahl/">wettbewerbswidriger Beeinflussung der freien Arztwahl</a>)'
          }
        }, {
          "@type": "Question",
          "name": "Wofür steht die Abkürzung D.D.S.?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'D.D.S. steht für: Doctor of dental surgery'
          }
        }]
    };


    var faqEN = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity":
        [{
          "@type": "Question",
          "name": "Are you still accepting new patients?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>Yes. Through practice expansion, extended opening hours and changes in practice organization, we have created additional capacity and are currently still able to admit new patients. We look forward to your visit!</p><p>You can find further information on booking appointments here: <a href="https://www.zahnarztpraxis-heindel.de/en/praxis/termin-buchen">Book an appointment</a></p>'
          }
        }, {
          "@type": "Question",
          "name": "I have lost my bonus booklet, can you issue me a duplicate?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Yes, we can access the data of the last 10 years in our practice or our two previous practices and use this information to reissue the bonus booklet.</p>"
              +"<p>Please make an appointment to pick it up, as we process these requests collectively in order to optimize the practice processes. Unfortunately, we cannot hand out the documents spontaneously.</p>"
          }
        }, {
          "@type": "Question",
          "name": "How long do you store my data?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Your data will be stored for ten years due to legal requirements. This concerns: patient data, treatment records, records of dental treatments, radiographs and records of X-ray examinations."
          }
        }, {
          "@type": "Question",
          "name": "I have a question about my invoice. Who can I contact?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": ' <p>We work with the greatest possible care when billing, but it is still possible that an error may occur or simply an item on the invoice is unclear.</p>'
              +'<p>If you have received the invoice by post via DZR, you will find all the necessary information and contact details for queries in the top right-hand corner of the letterhead. Financial questions, e.g. arranging payment by installments, should be clarified directly with the DZR using the information provided there.</p>'
              +'<p>We will of course also answer any questions regarding the content of the invoice directly and personally. Please understand that we cannot do this spontaneously at reception. If possible, please describe your request briefly in writing or make an appointment for a personal discussion on site.</p>'
              +'<p>In case of doubt, you can also contact the <a href="https://www.zahnaerzte-in-sachsen.de/patienten/patientenberatung/">patient advisory service of the Saxony Dental Association</a>, which will support you confidentially and free of charge, regardless of your health insurance company.</p>'
          }
        }, {
          "@type": "Question",
          "name": "What do I do if another doctor needs my records?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>You fill out an appropriate power of attorney with the requesting physician. He will then contact us with the specific request and will receive all necessary documents directly from us via an encrypted e-mail.</p>'
              +'<p>Of course you can also pick up the documents personally. Please make an appointment to pick up the documents, as we process these requests collectively in order to optimize the practice processes. Unfortunately, we cannot hand over the documents spontaneously.</p>'
          }
        }, {
          "@type": "Question",
          "name": "Do you also carry out treatments under general anesthesia?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No."
          }
        }, {
          "@type": "Question",
          "name": "Can I pay you in cash?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No."
          }
        }, {
          "@type": "Question",
          "name": "I have a private supplementary insurance. What is the procedure?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "We draw up a treatment plan for you, which you submit to your insurance company. After receiving the decision, please contact us to make an appointment for the continuation of your treatment."
          }
        }, {
          "@type": "Question",
          "name": "I do not speak German. Can you treat me anyway?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": '<p>Yes, of course! We just have to make sure that we can communicate with each other.</p><p>Learn more: <a href="https://www.zahnarztpraxis-heindel.de/en/praxis/kommunikation">Treatment of patients who do not speak German</a></p>'
          }
        }, {
          "@type": "Question",
          "name": "I have an appointment, why do I have to wait anyway?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Our dental practice works with a so-called time-boxing model, in which fixed times are scheduled for planned treatments based on experience. If, for example, a need for treatment is identified during a check-up appointment, a new appointment is made for this, unless it can still be accommodated in the time window already available.</p>"
            +"<p>In this way we ensure that nobody has to wait unnecessarily long.</p>"
            +"<p>Nevertheless, there can always be unplanned complications during the treatment before you, which can cause your appointment to be postponed somewhat.</p>"
            +"<p>We also have to give patients with acute pain the opportunity to be treated without an appointment planned weeks in advance, a so-called emergency treatment. We have optimized time windows for this, but even here it can happen that an unplanned pain treatment by your predecessor delays the start of your treatment.</p>"
            +"<p>As soon as such delays can be estimated by us, we will contact you and discuss further options. (estimated waiting time, possibly postponement of your appointment if you cannot wait any longer, etc.)</p>"
            +"<p>In general, we recommend that you allow for a buffer of about 5-10 minutes before the treatment (filling out the medical history sheet, etc.) and if possible, a buffer of 30 minutes at the back.</p>"
          }
        }, {
          "@type": "Question",
          "name": "Tooth Rescue Box",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Milk teeth that have been knocked out are not re-inserted, but permanent teeth can grow back if the cells of the periodontal membrane survive. A tooth rescue box can help. Ask for &quot;DENTOSAFE&quot; in your pharmacy, for example."
          }
        }, {
          "@type": "Question",
          "name": "Do you also make house calls?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'Unfortunately, at present we can only make house calls in exceptional cases due to scheduling reasons. If you are unable to make an appointment with us, you can call phone <a href="tel:116117" style="color: #ff007f" >116 117</a> to arrange for a dentist to make house calls.'
          }
        }, {
          "@type": "Question",
          "name": "What does the abbreviation D.D.S. stand for?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": 'D.D.S. stands for: doctor of dental surgery'
          }
        }]
    };

    let scriptInternal = [];
    scriptInternal.push({
      hid: 'schemaFaq',
      json: (this.$i18n.locale=='de' ? faqDE : faqEN),
      type: 'application/ld+json'
    });

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.faq"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
      headAttrs: {
        //itemscope: true,
        //itemprop: "mainEntity",
        //itemtype: "https://schema.org/FAQPage",
      },
      script: scriptInternal,
      meta: [
        /*{ vmid:'charset', property: 'charset', content:'utf-16' },*/
        /*{ vmid:'description', name: 'description', content: 'Hello2', vmid: 'test' },*/
      ],      
    };
  },  
};




</script>
