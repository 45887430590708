// prettier-ignore
<!-- prettier-ignore -->
<template>
  <div class="wrapper">
    
    <parallax
      class="page-header header-filter header-smaller"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.professionelle-zahnreinigung-pzr-prophylaxe") }}
            </h1>
            <!-- <h4>
              {{ $t("pzr.claim") }}
            </h4>-->
          </div>
        </div>
      </div>
    </parallax>

    <div class="main main-raised">
      <div class="section section-text pb-0">


        <div class="container" style="padding-bottom: 60px">

          <!-- <h2 className="{classes.sectionTitle}">Professionelle Zahnreinigung (PZR) und Prophylaxe</h2> -->

          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100">
              <div v-if="$i18n.locale == 'de'">


<h3 itemprop="name">Was ist eine professionelle Zahnreinigung?</h3>
<p>
  Die professionelle Zahnreinigung dient der Beseitigung von Biofilm, Zahnbelägen, Verfärbungen, die mit der Zahnbürste schwer erreichbar sind oder zu Hause nicht mehr entfernen lassen und dadurch bei der täglichen Zahnhygiene oft auf der Strecke bleiben. Somit werden bei der PZR gleichzeitig Bakterien entfernt, die Karies oder Entzündungen sowie weiter schreitende Parodontitis verursachen können. Die professionelle Zahnreinigung schützt das Immunsystem und das Kariesrisiko wird um 70 Prozent verringert. Durch die Behandlung werden die Zähne wieder glänzend und der Biofilm wird entfernt. Der Zahnschmelz wird nicht angegriffen.
</p>
<h3 itemprop="name">Warum sollte ich eine professionelle Zahnreinigung durchführen lassen?</h3>

<p>
  Eine PZR ist aus folgenden Gründen notwendig:
</p>
<ul>
  <li>Zahnerkrankungen vorbeugen (v.a. Karies und Parodontose, Knochenabbau, Zahnfleischrückgang)</li>
  <li>hellere Zähne (Verfärbungen und Beläge reduzieren)</li>
  <li>Stärkung des Immunsystems</li>
  <li>allgemeine Krankheitsvorsorge (Herzinfarkt, Diabetes, Rheuma etc.)</li>
</ul>
<p>
  Bei der häuslichen Zahnpflege erreichen Sie ca. 2/3 der Zahnoberflächen. Selbst mit Zahnseide und Mundspülung bleibt ca. 1/3 unberührt und bildet einen idealen Nährboden für Bakterien. Die Prophylaxe senkt das Kariesrisiko um 70%.
</p>
<p>
  Neben zahngesundheitlichen Aspekten, spielen auch allgemeine medizinische Gründe eine wichtige Rolle. Wissenschaftlichen Untersuchungen zufolge hilft eine Prophylaxe auch bei der Prävention von Herzinfarkten, Diabetes, Rheuma und anderen Krankheiten.
</p>
<p>
  Gerade hintere Zähne und Zahnzwischenräume kommen auch bei guter Mundhygiene oft zu kurz.  Zähneputzen schützt vor akuter Zahnfleischentzündung und Karies. Dennoch können sich an schwer zugänglichen Stellen Zahnbeläge und Zahnfleischtaschen bilden, die zu Zahnstein aushärten, wenn sie nicht regelmäßig entfernt werden. Bei chronischer Zahnfleischentzündung treten zuerst keine Schmerzen auf, wodurch die Entzündung fortschreiten und zu Knochenabbau und Zahnausfall führen kann (Parodontitis oder umgangssprachlich auch Parodontose genannt).  Durch die Professionelle Zahnreinigung (PZR) kann dem vorgebeugt werden.
</p>



              </div>

            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <pricing-card>
                <template slot="cardContent">
                  <h6 class="card-category text-success">Eine PZR kostet ca. 125 EUR (Stand 06/2024)</h6>
                  <h1 class="card-title">Bis zu 60 &euro; zurück erhalten!</h1>
                  <p>
                    Gesetzliche Krankenkassen übernehmen bis zu 60 EUR der Kosten für die professionelle Zahnreinigung.<br />
                    <br />
                    Bitte erkundigen Sie sich <span style="text-decoration:underline;">vor der Behandlung</span> bei Ihrer Versicherung, ob und in welchem Umfang diese sich an Ihrer Prophylaxe beteiligt!

                  </p>
                  <ul>
                    <!-- <li><b>70%</b> geringeres Kariesrisiko</li>
                    <li><b>5</b> Team Members</li>
                    <li><b>55</b> Personal Contacts</li>
                    <li><b>5.000</b> Messages</li>-->


                    <li>
                      <md-icon class="text-success"> check </md-icon> besonders schonend und schmerzfrei dank Pulverstrahlgerät<!--luftbetriebenem Zahnpolitursystem-->
                    </li>
                    <li>
                      <md-icon class="text-success"> check </md-icon> gesundes Zahnfleisch und hellere Zähne
                    </li>
                    <li>
                      <md-icon class="text-success"> check </md-icon> 70% geringeres Kariesrisiko<!-- durch fürhzeitige Erkennung von Karies in Zwischenräumen und unter dem Zahnfleich-->
                    </li>
                    <li>
                      <md-icon class="text-success"> check </md-icon> teuren Zahnersatz vermeiden
                    </li>
                  </ul>
                  <router-link :to="$i18nRoute({ name: 'bookOnline' })">
                    <md-button
                      href="#"
                      class="md-primary md-round"
                    ><i class="material-icons">phone</i>
                      Jetzt Termin vereinbaren!
                    </md-button>
                  </router-link>
                </template>
              </pricing-card>
            </div>
          </div>
          <div>
            
            

<!-- <h3 itemprop="name">Wie funktioniert eine professionelle Zahnreinigung?</h3>-->
<p>
  Professionell gereinigte Zähne dienen zur Erhaltung der Gesundheit des gesamten Körpers. Mit der fachmännischen Zahnreinigung werden etwa 30 Prozent mehr an Zahnflächen erreicht, als bei der häuslichen Zahnhygiene.
</p>
<p>
  Besonders hartnäckige Beläge werden beseitigt und die Zähne anschließend poliert. So können sich an den rauen Stellen, wo der Zahnstein entfernt wurde, nicht mehr so schnell Bakterien ansetzen und vermehren. Zum Abschluss einer PZR kommt Fluoridlack auf die Zähne. Damit wird der Zahnschmelz gehärtet und vor neuen Bakterienangriffen geschützt.
</p>
<h3 itemprop="name">Wie läuft die professionelle Zahnreinigung ab?</h3>
<p>
  Wir führen die professionelle Zahnreinigung nach den aktuellen wissenschaftlichen Empfehlungen aus.
</p>
<p>
  Unser motiviertes Prophylaxeteam nimmt sich Zeit für Ihre Vorsorge.
</p>
<ul>
  <li>Die Behandlungen finden nur nach Terminvergabe statt und dauern ca. 60 Minuten.</li>  
  <li>Die Prophylaxe-Fachkräfte beraten individuell über Mundhygiene und Behandlungsablauf / Anfärben der Zähne.</li>
  <li>Entfernung von harten sowie auch weichen Zahnbelägen mit Handinstrumenten und modernsten Ultraschallinstrumenten</li>
  <li>Entfernung von unästhetischen Zahnverfärbungen mittels eines für den Zahnschmelz schonenden Pulverstrahlgerätes.</li>
  <li>Befreiung der Zahnzwischenräume von Konkrementen mit Polierstreifen und Zahnseide</li>
  <li>Vorsichtige Zahnpolitur und Versiegelung mit schützendem und härtendem Fluoridlack.</li>
  <li>Einschätzung des Erkrankungsrisikos / Empfehlung für Zeitintervall für weitere Zahnreinigungen</li>
  <li>Zum Schluss überprüft ein Zahnarzt die Behandlung und führt den Kontrolltermin für Ihr Zahnbohnusheft durch.</li>
</ul>
<p>
  Wir prüfen vor Behandlungsbeginn Ihren aktuellen Befund und erstellen passend dazu eine Mehrkostenvereinbarung, bei der verbindlich die Kosten vereinbart werden. Eine Kopie dieser Vereinbarung erhalten Sie automatisch per E-Mail. Die Bezahlung erfolgt bequem per Rechnung, was Ihnen auch Zeit für eine eventuelle Rückerstattung durch die Krankenkasse gibt.
</p>


<h3>Wir verwenden ein besonders schonendes luftbetriebenes Zahnpolitursystem!</h3>
<p>
  Mit einem speziellen Pulverstrahlgerät entfernen wir Biofilm, Verfärbungen und jungen Zahnstein auf natürlichen Zähnen, Restaurationen und Implantaten. Wir reinigen und polieren dabei in einem Schritt.
</p>
<p>
  Lästiges Kratzen mit Instrumenten am Zahn wird dadurch vermieden.
</p>
<p>
  Weitere Informationen inkl. <a href="https://www.ems-dental.com/de/guided-biofilm-therapy/gbt-04-airflow">Beispiel-Videos finden Sie z.B. auf der Website von EMS</a>.
</p>
<img :src="airflow" width="1448" height="1080" alt="Schematische Darstellung des AIRFLOW&reg; Prozesses"/>
<p>
  Bildquelle: <a href="https://www.ems-dental.com/de/guided-biofilm-therapy/gbt-04-airflow">E.M.S. Electro Medical Systems S.A.</a>
</p>


<h3>Wer führt die Prophylaxe durch?</h3>

<p>
  Die professionelle Zahnreinigung bei der Zahnarztpraxis Heindel wird von unseren Zahnmedizinischen Fachangestellten (ZFA) durchgeführt. Unsere ZFAs sind im Umgang mit speziellen Handinstrumenten und Geräten zur Reinigung der Zähne geschult.
</p>
<p>
  Sie erkennen sich anbahnende Zahnerkrankungen und führen die Behandlung zuverlässig und schonend durch.
</p>

<h3>Wie lang dauert die Behandlung?</h3>
<p>Die Behandlung dauert ca. 1 Stunde.</p>

<h3>Wie häufig sollte man eine professionelle Zahnreinigung durchführen lassen?</h3>
<!-- <p>Mindestenes 1-mal pro Jahr wird die professionelle Zahnreinigung ausdrücklich von der Bundeszahnärztekammer empfohlen.</p>-->
<p>
  Wie oft Sie eine Prophylaxe durchführen sollten hängt von Ihrer persönlichen Mundhygiene ab.
</p><p>
  Es herrscht unter Zahnmedizinern Einigkeit darüber, dass eine Prophylaxe mindestens alle 6 Monate bzw. 2 mal im Jahr notwendig ist.
</p><p>
  Andernfalls sammeln sich Bakterien auf dem Zahnschmelz (Plaque) und greifen ihn an. Sobald der Zahnschmelz zerstört ist, ist der Zahn schutzlos und erkrankt. Im schlimmsten Fall droht der Verlust eines oder mehrerer Zähne.
</p>
<p>Bei erhöhtem Risiko, an Karies oder Parodontitis, ist ggfs. mehrmals im Jahr eine Zahnreinigung indiziert</p>
<p>In folgenden Fällen ist eine Prophylaxe alle 3 Monate bzw. 4 mal im Jahr nötig:</p>
<ul>
  <li>Raucher</li>
  <li>schnelle Zahnsteinbildung</li>
  <li>starke Zahnfehlstellungen</li>
  <li>Karies Veranlagung</li>
  <li>Parodontitis-Vorerkrankung</li>
  <li>tiefe Zahnfleischtaschen</li>
</ul>
<p>
  Auch andere Faktoren, wie z.B. die individuellen Ernährungsgewohnheiten (Kaffee, Tee , Rotwein, dunkelgrünes Gemüse ) sowie die Einnahme unterschiedlicher Medikamente (antiepileptika, Herzmedikamente, Blutdruckmedikamente) oder die Besonderheiten unseres Speichels können dazu führen, dass Zahnstein bei manchen Patienten schneller als bei manchen anderen entsteht und dass die Zähne sich durch die natürlichen Farbstoffe schwarz oder gelblich verfärben lassen.
</p>
<p>
  Zusätzlich können auch die hormonellen Veränderungen in der Pubertät oder bei Schwangerschaft auf das Zahnfleisch wirken und zu einer Neigung zur Zahnfleischblutung führen. Es wird deswegen inbesondere im ersten Trimester einer Schwangerschaft die Durchführung einer professionellen Zahnreinigung empfohlen.
</p>
<p>
  Diesen Problemen (Blutung, Zahnstein, Verfärbungen oder sogar Vergrößerungen des Zahnfleisches) kann man durch die professionelle Zahnreinigung und Prophylaxe entgegenwirken.
</p>

<h3>Wie erfolgt die Abrechnung und Bezahlung?</h3>
<p>
  Die professionelle Zahnreinigung wird von den meisten gesetzlichen Krankenkassen nicht erstattet. Allerdings beteiligen sich immer mehr Krankenkassen an den Kosten. Bitte fragen Sie deshalb bei Ihrer Krankenkasse nach. Eventuell können Sie die Rechnung dort einreichen. Eine Direktabrechnung zwischen der Zahnarztpraxis Heindel und Ihrer Krankenkasse ist derzeit noch nicht möglich.
</p>
<p>Ablauf:</p>
<ol>
  <li>Melden Sie sich vor Ihrem Zahnreinigungstermin bei Ihrer Krankenkasse, um sich in Bezug auf eine mögliche Kostenübernahme zu informieren. Viele Krankenkassen unterstützen Ihre jährliche Zahnvorsorge mit einem kleinem Beitrag.</li>
  <li>Wir erstellen vor Behandlungsbeginn anhand Ihres aktuellen Befundes eine verbindliche Mehrkostenvereinbarung. Sie erhalten automatisch ein Kopie dieser Unterlagen per E-Mail.</li>
  <li>Sie erhalten im Anschluss an die Behandlung über unseren Dienstleister DZR eine Rechnung.</li>
  <li>Falls Ihre Krankenkasse sich ganz oder teilweise an den Kosten beteiligt, können Sie diese Rechnung dann dort einreichen und erhalten die Kosten i.d.R zeitnah zurück, noch bevor die Zahlungsfrist der Rechnung abgelaufen ist.</li>
</ol>



<a name="kosten"/>

<div class="md-layout">

  <div class="md-layout-item md-size-100 md-small-size-100">
    <h3>Welche Krankenkasse zahlt wieviel?</h3>
    <a href="https://www.kzbv.de/pzr-zuschuss.1033.de.html" target="kzbv"><img style="float:right; padding-right: 12px; padding-bottom: 6px;" :src="pzrzuschuss" width="260" Xeight="452" alt="Professionelle Zahnreinigung (PZR) - Übersicht der Leistungen der gesetzlichen Krankenkassen - Umfrage 2022"/></a>
    <p>Die professionelle Zahnreinigung ist keine Kassenleistung, wird aber als freiwillige Satzungsleistung von fast allen <!-- bei 85 von 97 -->Krankenkassen <!-- (zw. 10 – 250,- EUR pro Jahr, tw. an Bonussystem gekoppelt) -->ganz oder teilweise abgedeckt.</p>
    <p>
      Die Kassenzahnärztliche Bundesvereinigung führt regelmäßig eine Umfrage zum PZR-Zuschuss bei den gesetzlichen Krankenkassen durch und veröffentlicht die Ergebnisse auf ihrer Website:      
    </p>
    <p>Eine andere gute Übersicht zu weiteren Krankenversicherungen finden Sie z.B. hier: <a href="https://www.verbraucherzentrale.de/sites/default/files/2024-02/kzbv_pzr-zuschuss_krankenkassen_2024-01.pdf" target="kzbv">https://www.kzbv.de/pzr-zuschuss</a></p>
      <ul>
        <li><a href="https://www.versicherungsbote.de/id/4907159/Professionelle-Zahnreinigung-Was-die-Krankenkassen-bezuschussen/" target="versicherungsbote">https://www.versicherungsbote.de/id/4907159/Professionelle-Zahnreinigung-Was-die-Krankenkassen-bezuschussen/</a></li>
        <li>Verbraucherzentrale: <a href="https://www.verbraucherzentrale.de/sites/default/files/2024-02/kzbv_pzr-zuschuss_krankenkassen_2024-01.pdf" target="kzbv">KZBV PZR Zuschuss Krankenkassen 2024</a></li>
      </ul>
    
  </div>
</div>







<h2>Häufig gestellte Fragen (FAQ)</h2>





<h3>Welche Materialen, Geräte und Hersteller verwenden wir?</h3>
<ul>
  <li>EMS Guided Biofilm Discloser - zur verdeutlichung des Biofilms für genaue Putzhinweise</li>
  <li>Flourlacke: Sensodyne ProSchmelz Fluorid Gelee, elmex gelée, colgate Duraphat</li>
  <li>Interndetalbürstchen von TePe für jeden Zwischenraum individuell angepasst auf den Patienten</li>
  <li>Polierpasten: Kerr CleanPolish, ivoclar vivadent Proxyt Medium/Mittel, ivoclar vivadent Proxyt Fine/Fein - verschiedene Polierpasten für eine glatte Oberfläche</li>
  <li>Oral-B Superfloss, Oral-B Pro-Expert Premium, Mara expert (Lemon-Minze) - Zahnseide individuell für Patienten und Handhabung/Zahnstellung / Zahnersatz</li>
  <li>Airflow-Pulver von NSK (ohne Geschmack)</li>
  <li>Mundspülung: Dynexidin (bei stark blutenden Patienten)</li>
</ul>

<h3>Darf ich nach der Zahnreinigung essen und trinken?</h3>
<p>
  Nach der Professionellen Zahnreinigung empfehlen wir Ihnen mindestens 1 Stunde lang nichts zu essen. Das ist nötig, damit der Fluoridlack vollständig aushärtet und optimalen Schutz für Ihre Zähne bietet.
</p>
<p>
  Wir empfehlen Ihnen außerdem ca. 8 Stunden lang auf farbstoffhaltige Getränke und Speisen wie z.B. Kaffee, Cola, Rotwein etc. zu verzichten. In dieser Zeit sind Ihre Zähne besonders anfällig für neue Farbablagerungen.
</p>

<h3>Ist eine professionelle Zahnreinigung mit Schmerzen verbunden?</h3>
<p>
  Normalerweise verspüren Sie während der Behandlung keine Schmerzen. Unser Team ist bestens ausgebildet und arbeitet vorsichtig und schonend an Ihren Zähnen.
</p><p>
  Empfindliche Personen können ein leichtes Kratzen oder Prickeln an den Zahnoberflächen oder am Zahnfleisch spüren.
</p><p>
Sollte allerdings eine Zahnfleischentzündung oder eine Erkrankung des Kieferknochens vorliegen, ist es möglich, dass Sie empfindlicher auf bestimmte Reize reagieren. Oftmals liegt das daran, dass die empfindlichen Zahnhälse freiliegen, welche normalerweise unter dem Zahnfleisch verborgen sind.
Für den Fall, dass der Schmerz für Sie unerträglich ist, brechen wir die Behandlung ab und bieten Ihnen eine lokale Betäubung an.
</p>

<h3>Entfernung von Zahnstein</h3>
<p>
  Entfernung von Zahnstein (harte Zahnbeläge, die am Schmelz anhaften) im Rahmen von Kontrollterminen oder anderen Behandlungen kann 1 x pro Jahr von den Krankenkassen übernommen werden. Dies stellt jedoch keinen Ersatz für eine PZR dar.
</p>
<!--<p>
  BEMA 107 (Zst), 16 Punkte (Bsp.: AOK - 19,21 EUR) 
</p>-->

<h3>Reinigung von Brücken</h3>
<p>
  Brücken sind keine Zähne, müssen aber ebenfalls gereinigt werden und werden deshalb wie ein Zahn abgerechnet.
</p>
<h3>Zähne putzen</h3>
<p>Auch wenn eine Professionelle Zahnreinigung durchgeführt wird, sollte man vorher gründlich die Zähne putzen.</p>


</div>
         


          
        </div>
      </div>
    </div>
<!-- 
    <div class="section section-text pb-0">        
    </div>

    <div class="main main-raised">
      <div class="section section-text pb-0">        
      </div>
    </div>

-->

  </div><!-- end-wrapper -->

</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { PricingCard, RotatingCard, Modal } from "@/components";

export default {
  components: {
    PricingCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {},
  data() {
    return {
      classicModal: false,
      manualRotateState: false,
      manualRotateState2: false,
      manualRotateState3: false,
      image: require("@/assets/img/zaph/socaldental-care-l0DtHcv9jfQ-unsplash.jpg") /* visuals-0RPf107BLek-unsplash-large */,
      placeholder: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-3_small.jpg"),
      airflow: require("@/assets/img/airflow.png"),
      pzrzuschuss: require("@/assets/img/kzbv-pzr-zuschuss.png"),
      cardRotating: {
        cardRotatingBg1: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-3_small.jpg"),
        cardRotatingBg2: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-3_small.jpg"),
      },
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.professionelle-zahnreinigung-pzr-prophylaxe"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
