<template>
  <span>
    <drop-down direction="down">
      <md-button class="md-button md-button-link md-white dropdown-toggle"
        ><md-icon>language</md-icon> {{ $i18n.locale }}</md-button
      >
      <ul class="dropdown-menu dropdown-with-icons">
        <li
          v-for="locale in supportedLocales"
          :key="locale"
          @click="switchLocale(locale)"
        >
          <a class="locale-name">
            {{ $t("trans." + locale) }}
          </a>
        </li>
      </ul>
    </drop-down>
  </span>
</template>

<script>
import { Trans } from "@/plugins/translation";

export default {
  name: "LocaleSwitcher",
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: underline;
  cursor: pointer;
}
</style>
