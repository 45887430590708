var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-card',{staticClass:"md-card-profile",class:{
    'md-card-plain': _vm.cardPlain,
    'flex-direction-row': _vm.flexDirectionRow,
    'md-card-horizontal': _vm.type === 'horizontal',
  }},[(_vm.type !== 'horizontal')?[_c('md-card-header',{class:{
        'md-card-header-image': !_vm.cardAvatar,
        'md-card-avatar': _vm.cardAvatar,
      },attrs:{"shadow-normal":_vm.shadowNormal,"no-colored-shadow":_vm.noColoredShadow}},[_c('a',{attrs:{"href":_vm.cardLink()}},[_c('img',{staticClass:"img",attrs:{"src":_vm.cardImage}}),(_vm.$slots.cardTitleHeader)?_c('div',{staticClass:"card-title"},[_vm._t("cardTitleHeader")],2):_vm._e()]),(!_vm.noColoredShadow && !_vm.shadowNormal)?_c('div',{staticClass:"colored-shadow",style:(_vm.shadowImageProfile(_vm.cardImage))}):_vm._e()]),(_vm.$slots.cardContent)?_c('md-card-content',[_vm._t("cardContent")],2):_vm._e(),(_vm.$slots.cardAction)?_c('md-card-actions',{staticClass:"justify-content-center"},[_vm._t("cardAction")],2):_vm._e()]:[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item",class:{
          'md-size-45 md-xsmall-size-100': !_vm.smallImg,
          'md-size-33 md-small-size-100': _vm.smallImg,
        }},[_c('md-card-header',{staticClass:"md-card-header-image",attrs:{"shadow-normal":_vm.shadowNormal,"no-colored-shadow":_vm.noColoredShadow}},[_c('a',{attrs:{"href":_vm.cardLink()}},[_c('img',{staticClass:"img",attrs:{"src":_vm.cardImage}})]),(!_vm.noColoredShadow && !_vm.shadowNormal)?_c('div',{staticClass:"colored-shadow",style:(_vm.shadowImageProfile(_vm.cardImage))}):_vm._e()])],1),_c('div',{staticClass:"md-layout-item",class:{
          'md-size-55 md-xsmall-size-100': !_vm.smallImg,
          'md-size-66 md-small-size-100': _vm.smallImg,
        }},[(_vm.$slots.cardContent)?_c('md-card-content',[_vm._t("cardContent")],2):_vm._e(),(_vm.$slots.cardAction)?_c('md-card-actions',{class:{ 'justify-content-center': _vm.textCenter },attrs:{"md-alignment":"left"}},[_vm._t("cardAction")],2):_vm._e()],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }