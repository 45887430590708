<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.takeover") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container">
          <div v-if="$i18n.locale == 'de'">
            <p>
              Wir haben zum 1. Juli 2020 die Praxen von
              <strong>Dr. med. Reingard Ittlinger</strong> und
              <strong>Dr. med. Elke Stiller</strong> übernommen und sind
              <strong
                >weiterhin am bekannten Ort für die ehemaligen Patienten
                da</strong
              >. Die beiden Kolleginnen haben sich in den Ruhestand
              verabschiedet und ihre Praxen vertrauensvoll in unsere Hände
              gegeben.
            </p>
            <p>
              Die Archivdaten und Röntgenaufnahmen werden in unserer neuen
              Praxis in einem besonders gesicherten Archiv vorgehalten und sind
              nach erteilter Einverständniserklärung durch die Patientin / den
              Patienten weiterhin nutzbar.
            </p>
            <!--<ul>
  <li>Einverständniserklärung zur Datenübernahme</li>  
  </ul>-->
            <p>
              Im Zuge der Übernahme haben wir umfangreiche
              Modernisierungsarbeiten in der Praxis durchgeführt.
            </p>
            <p style="text-decoration: underline">
              In der Ihnen vertrauten Umgebung erwarten Sie heute folgende
              Neuerungen:
            </p>
            <ul>
              <li>Verlängerte Öffnungszeiten</li>
              <li>Kostenloses WLAN für unsere Gäste</li>
              <li>Ab sofort sind Kartenzahlung und Finanzierung möglich</li>
              <li>
                Ein ständig besetzter Empfang, der abgetrennt vom modernisierten
                Wartebereich den Schutz Ihrer Daten wahrt
              </li>
              <li>
                Eigenes Dentallabor für schnelle und kostengünstige Reparaturen
                vor Ort
              </li>
              <li>
                Neue digitale Röntgentechnik:
                <ul>
                  <li>
                    Aufnahmen sind jetzt in jedem Behandlungsraum möglich. Sie
                    können einfach sitzen bleiben.
                  </li>
                  <li>
                    Dünne Speicherfolien, anstatt unangenehmer Sensorblöcke im
                    Mund, sorgen für einen angenehmeren Besuch beim Zahnarzt.
                  </li>
                </ul>
              </li>
              <li>
                Moderne Behandlungsformen wie CEREC 3D und PRF-Verfahren
                unterstützen uns optimal beim Erhalt Ihrer Zahngesundheit.
              </li>
              <li>
                Digitale Anamnese
                <ul>
                  <li>
                    Auf Ihrer elektronischen Gesundheitskarte (eGK) gespeicherte
                    Medikationspläne können wir auslesen und bei der Behandlung
                    berücksichtigen
                  </li>
                  <li>
                    Der Behandler sieht die wichtigen Informationen sofort in
                    Ihrer Patientenakte
                  </li>
                </ul>
              </li>
              <li>
                Terminbuchung über das Internet, ohne lästige Wartezeiten am
                Telefon
              </li>
              <li>
                Zusammenarbeit mit externen Spezialisten für Praxisverwaltung,
                Abrechnung und Rechnungswesen. Das erlaubt uns den Fokus auf
                unsere Kernkompetenzen und auf Sie als Patienten.
              </li>
            </ul>

            <p>Ich möchte mich Ihnen kurz vorstellen:</p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              On July 1st, 2020 we took over the practices of
              <strong>Dr. med. Reingard Ittlinger</strong> and
              <strong>Dr. med. Elke Stiller</strong> (both retired) and are
              <strong
                >still there for the former patients at the familiar
                location</strong
              >. The two colleagues have said goodbye for retirement and have
              confidently handed their practices into our hands.
            </p>
            <p>
              The archive data and X-rays are kept in a specially secured
              archive in our new practice and can still be used after the
              patient has given his / her consent.
            </p>
            <!--<ul>
  <li>Einverständniserklärung zur Datenübernahme</li>  
  </ul>-->
            <p>
              In the course of the takeover, we have carried out extensive
              modernization work in the practice.
            </p>
            <p style="text-decoration: underline">
              The following innovations await you today in a familiar
              environment:
            </p>
            <ul>
              <li>Extended opening times</li>
              <li>Free WiFi for our guests</li>
              <li>Card payments and financing are now possible</li>
              <li>
                A constantly manned reception, which is separated from the
                modernized waiting area and protects your data
              </li>
              <li>
                Our own dental laboratory for quick and inexpensive repairs on
                site
              </li>
              <li>
                New digital X-ray technology:
                <ul>
                  <li>
                    Recordings are now possible in every treatment room. You can
                    just stay seated.
                  </li>
                  <li>
                    Thin imaging plates instead of uncomfortable sensor blocks
                    in the mouth ensure a more pleasant visit to the dentist.
                  </li>
                </ul>
              </li>
              <li>
                Modern forms of treatment such as CEREC 3D and PRF procedures
                optimally support us in maintaining your dental health.
              </li>
              <li>
                Digital anamnesis
                <ul>
                  <li>
                    We can read out the medication plans stored on your
                    electronic health card (eGK) and take them into account
                    during treatment
                  </li>
                  <li>
                    The practitioner immediately sees the important information
                    in your patient file
                  </li>
                </ul>
              </li>
              <li>
                Appointments can be booked over the Internet without annoying
                waiting times on the phone
              </li>
              <li>
                Cooperation with external specialists for practice
                administration, billing and accounting. This allows us to focus
                on our core competencies and on you as a patient.
              </li>
            </ul>

            <p>I would like to introduce myself to you briefly:</p>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Iro Karouzaki-Heindel</h4>

                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Doctor of Dental Surgery, Zahnärztin, Inhaberin
                    </h6>

                    <p>
                      Mein Name ist Iro Karouzaki-Heindel, ich bin in Rom
                      geboren und auf der wunderschönen griechischen
                      Mittelmeerinsel Kreta aufgewachsen. Mit 17 bin ich in die
                      griechische Hauptstadt Athen gezogen, um das
                      zahnmedizinische Studium an der Kapodistrias Universität
                      zu besuchen, welches bis heute zu einem der 40 besten
                      zahnmedizinischen Studiengänge weltweit zählt.
                    </p>
                    <p>
                      In meiner Heimat habe ich nach dem Abschluss meines
                      Studiums 3 Jahre lang als Zahnärztin gearbeitet, in
                      allgemeinen Praxen sowie in Praxen für
                      Kiefer-Mund-Chirurgie.
                    </p>
                    <p>
                      Anschließend bin ich im Jahr 2013 in unser schönes Dresden
                      gezogen, um mich im Bereich der zahnärztlichen Prothetik
                      weiterzubilden. Im Rahmen dessen habe ich 3 Jahre lang in
                      der Poliklinik für zahnärztliche Prothetik bei Prof. Dr.
                      Michael Walter am Universitätsklinikum Carl Gustav Carus
                      Dresden gearbeitet. Für diese einmalige Chance möchte ich
                      mich bei Professor Walter für sein Vertrauen ganz herzlich
                      bedanken, denn ich hatte am Uniklinikum die Gelegenheit
                      komplexe Fälle zu behandeln und dadurch mein Fachwissen
                      und meine praktischen Fähigkeiten zu bereichern.
                    </p>
                    <p>
                      Parallel dazu habe ich in kleineren Praxen der Dresdner
                      Umgebung, sowie in größeren Versorgungszentren im Dresdner
                      Zentrum gearbeitet und konnte mich auch im Bereich
                      Implantologie und Endodontologie (Wurzelkanaltherapie)
                      weiterentwickeln.
                    </p>
                    <p>
                      Zu meinem zahnmedizinischen Alltag gehört die umfassende
                      Behandlung unserer Patienten.
                    </p>
                    <p>
                      Ich übe nicht nur mit Leidenschaft Zahnmedizin aus,
                      sondern auch die Wertschätzung eines respektvollen
                      Umganges mit unseren Patienten und einer ethischen
                      Patientenaufklärung. Diese beiden Sachen liegen mir am
                      Herzen!
                    </p>
                    <p>
                      Mit meiner langjährigen Erfahrung in der Zahnmedizin werde
                      ich mir die Zeit nehmen, um für Sie mit Rat und Tat da zu
                      sein.
                    </p>

                    <p>
                      Mein Team und ich freuen uns, Sie persönlich
                      kennenzulernen und Ihre Erwartungen sowohl in
                      zahnmedizinischer als auch in menschlicher Hinsicht zu
                      erfüllen.
                    </p>
                    <p><br />Mit freundlichen Grüßen,</p>
                    <p>
                      <i>Iro Karouzaki-Heindel</i><br />
                      Dresden, 1. Juli 2020<br />
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Doctor of Dental Surgery, Dentist, Owner
                    </h6>
                    <p>
                      My name is Iro Karouzaki-Heindel, I was born in Rome and
                      grew up on the beautiful Greek Mediterranean island of
                      Crete. When I was 17, I moved to the Greek capital,
                      Athens, to study dentistry at Kapodistrias University,
                      which is still one of the 40 best dental courses in the
                      world.
                    </p>
                    <p>
                      In my home country, after completing my studies, I worked
                      as a dentist for 3 years, in general practices as well as
                      in practices for jaw and mouth surgery.
                    </p>
                    <p>
                      Then I moved to our beautiful Dresden in 2013 to study
                      further in the field of dental prosthetics. As part of
                      this, I spent 3 years in the polyclinic for dental
                      prosthetics with Prof. Dr. Michael Walter worked at the
                      Carl Gustav Carus University Hospital in Dresden. For this
                      unique opportunity, I would like to thank Professor Walter
                      very much for his trust, because I had the opportunity to
                      treat complex cases at the university hospital and thereby
                      enrich my specialist knowledge and practical skills.
                    </p>
                    <p>
                      At the same time, I worked in smaller practices in the
                      Dresden area, as well as in larger care centers in the
                      Dresden Center and was also able to develop further in the
                      field of implantology and endodontics (root canal
                      therapy).
                    </p>
                    <p>
                      The comprehensive treatment of our patients is part of my
                      daily dental routine.
                    </p>
                    <p>
                      I am not only passionate about dentistry, I also value
                      treating our patients respectfully and providing ethical
                      patient education. These two things are close to my heart!
                    </p>
                    <p>
                      With my many years of experience in dentistry, I will take
                      the time to be there for you with words and deeds.
                    </p>

                    <p>
                      My team and I look forward to getting to know you
                      personally and meeting your expectations in both dental
                      and human terms.
                    </p>
                    <p><br />Best regards,</p>
                    <p>
                      <i>Iro Karouzaki-Heindel</i><br />
                      Dresden, 1st of July 2020<br />
                    </p>
                  </div>
                </template>
              </profile-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileCard } from "@/components";

import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProfileCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      cardProfiles: {
        cardProfile1: require("@/assets/img/zaph/iro.jpeg"),
      },
      image: require("@/assets/img/zaph/ian-schneider-TamMbr4okv4-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.takeover"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}

.md-table-head {
  display: none;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
