<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.preparation") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <h3>Ihr erster Besuch pro Quartal / Behandlung</h3>
            <p>
              Für Ihren ersten Besuch bitten wir Sie Folgendes mitzubringen:
            </p>
            <ul>
              <li>
                Ihre Versichertenkarte / bei Privatpatienten Ihren
                Personalausweis
              </li>
              <li>
                Medizinische Vorbefunde und Röntgenbilder (soweit vorhanden)
              </li>
              <li>
                Eine Auflistung Ihrer Medikamente einschließlich Dosierungen
              </li>
              <li>
                Bonusheft der Krankenkasse mitbringen (für gesetzlich
                Versicherte, Patienten über 18 Jahre einmal pro Jahr, Kinder und
                Jugendliche unter 18 Jahre zweimal pro Jahr)
              </li>
            </ul>

            <h3>Bei jedem Besuch</h3>
            <ul>
              <li>Bitte putzen Sie vor Ihrem Besuch gründlich Ihre Zähne</li>
              <li>
                Koffeein vermeiden! Dessen blutdrucksteigernde Wirkung kann die
                Behandlung erschweren, insbesondere bei Zahnextraktionen. Es
                kann auch die Wirksamkeit einer lokalen Betäubung negativ
                beeinflussen.
              </li>
              <li>
                Planen Sie genügend Zeit für die Anfahrt ein. So kommen Sie
                entspannter an und gehen konzentrierter in die Untersuchung /
                Behandlung
              </li>
              <li>
                Bitte seien Sie pünktlich. 5 - 10 Minuten vor Ihrem Termin sind
                empfehlenswert, da z.B. auch der Anamnesebogen ausgefüllt werden
                muss.
              </li>
              <li>
                Planen Sie nach hinten raus einen Puffer von ca. 30 Minuten ein,
                da es aufgrund von Notfallpatienten zu leichten Verzögerungen
                Ihres Behandlungsbeginns kommen kann.
              </li>
            </ul>

            <h3>Für Angstpatienten</h3>
            <ul>
              <li>
                Teilen Sie unseren Empfangsmitarbeitern bereits bei der
                Terminvereinbarung mit, falls Sie Angst vorm Zahnarzt haben.
                Wenn wir informiert sind, können wir uns besser auf Sie
                einrichten und zum Beispiel einen Kennenlerntermin vereinbaren,
                bei dem keine Behandlung stattfindet.
              </li>
              <li>
                Vermeiden Sie die Einnahme von beruhigenden Medikamten auf
                eigene Faust, da Wechselwirkungen mit anderen Medikamenten
                möglich sind. Bei Bedarf kann der Zahnarzt Ihnen angstlösende
                Mittel geben.
              </li>
            </ul>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <h3>Your first visit per quarter / treatment</h3>
            <p>
              For your first visit we ask you to bring the following with you:
            </p>
            <ul>
              <li>
                Your insurance card / for private patients your identity card
              </li>
              <li>Preliminary medical findings and X-rays (if available)</li>
              <li>A list of your medications including dosages</li>
              <li>
                Bring your health insurance company's bonus booklet with you
                (for patients with statutory health insurance, patients over 18
                once a year, children and young people under 18 twice a year)
              </li>
            </ul>

            <h3>At each visit</h3>
            <ul>
              <li>Please brush your teeth thoroughly before your visit</li>
              <li>
                Avoid caffeine! Its blood pressure-increasing effect can make
                treatment more difficult, especially in the case of tooth
                extractions. It can also negatively influence the effectiveness
                of local anesthesia.
              </li>
              <li>
                Plan enough time for the journey. This way you arrive more
                relaxed and go into the examination / treatment more
                concentrated
              </li>
              <li>
                Please be punctual. 5 - 10 minutes before your appointment are
                recommended, because e.g. the medical history form must also be
                filled out.
              </li>
              <li>
                Plan for a buffer of about 30 minutes at the back, as there may
                be slight delays in the start of your treatment due to emergency
                patients.
              </li>
            </ul>

            <h3>For anxiety patients</h3>
            <ul>
              <li>
                Let our receptionists know when you make your appointment if you
                are afraid of the dentist. If we are informed, we can better
                prepare for you and, for example, arrange a meeting where no
                treatment takes place.
              </li>
              <li>
                Avoid taking sedative medications on your own, as they may
                interact with other medications. If necessary, the dentist can
                give you anxiety-relieving medication.
              </li>
            </ul>
          </div>
          <!-- 
              <h3>Für Kinder</h3>
              <ul>                
                <li></li>
              </ul>
-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/zaph-bg04.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.preparation"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
