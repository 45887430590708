// prettier-ignore
<!-- prettier-ignore -->
<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.laboratory") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <p>
              Unsere Praxis ist mit einem eigenen Labor für die Fertigung von
              hochwertigem Zahnersatz, Prothesen &amp; Reparaturen ausgestattet.
              So können wir einfache Reparaturen schnell und kostengünstig vor
              Ort ausführen.
            </p>

            <p>Weitere Vorteile für Sie:</p>
            <ul>
              <li>
                Enge Zusammenarbeit zwischen Zahnarzt und Zahntechniker durch
                kurze Wege
              </li>
              <li>
                Genauere Farbanpassung möglich, da Zahntechniker zu Behandlung
                dazukommen kann
              </li>
              <li>
                Einfachere Abstimmung direkt zwischen Patient, Zahnarzt und
                Zahntechniker für komplexe Arbeiten
              </li>
              <li>
                Kleinere Reparaturen und Anpassung noch während des Termins
                möglich
              </li>
            </ul>
            <p>
              Darüber hinaus arbeiten wir mit den folgenden professionellen
              regionalen Zahnlaboren zusammen:
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              Our practice is equipped with its own laboratory for the
              production of high-quality dentures, prostheses and repairs. This
              enables us to carry out simple repairs quickly and cheaply on
              site.
            </p>

            <p>Further advantages for you:</p>
            <ul>
              <li>
                Close cooperation between dentist and dental technician thanks
                to short distances
              </li>
              <li>
                More precise color matching is possible, as a dental technician
                can come for treatment
              </li>
              <li>
                Easier coordination between the patient, dentist and dental
                technician for complex work
              </li>
              <li>
                Small repairs and adjustments are possible during the
                appointment
              </li>
            </ul>

            <p>
              In addition, we work with the following professional regional
              dental laboratories:
            </p>
          </div>
          <ul>
            <li>
              <a href="https://apex-dentallabor.de/" target="APEX"
                >APEX Dentallabor</a
              >
              (Dresden)
            </li>
            <li>
              <a href="https://dental-art-dresden.de/" target="dentalart"
                >Dental Art Dresden</a
              >
            </li>
            <li>
              <a href="https://bionik-dental.de/" target="bionikdental"
                >BIONIK-DENTAL Laboratories</a
              >
              (Berlin)
            </li>
            <li>
              <a href="https://kluge-dentaltechnik.de/" target="klugedental"
                >Dentaltechnik Kluge</a
              >
              (Dresden)
            </li>
            <li>
              <a href="https://variodental.de/" target="variodental"
                >varioDental</a
              >
              (Dresden)
            </li>
            <!-- <li><a href="https://www.k2dental.de/" target="K2">K2 DENTAL</a></li> -->
          </ul>
        </div>
      </div>
    </div><!-- 
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100 mr-auto">
        <testimonial-card>
          <md-icon slot="icon">format_quote</md-icon>
          <template slot="cardContent">
            <h5 class="card-description">
              "Your products, all the kits that I have downloaded from your site
              and worked with are sooo cool! I love the color mixtures, cards...
              everything. Keep up the great work!"
            </h5>
          </template>
          <template slot="cardAction">
            <h4 class="card-title">Alec Thompson</h4>
            <h6 class="card-category">@alecthompson</h6>
            <div class="md-card-avatar">
              <a href="javascript:void(0)">
                <img
                  class="img"
                  src="assets/img/faces/card-profile1-square.jpg"
                />
              </a>
            </div>
          </template>
        </testimonial-card>
      </div>
    </div>-->
  </div>
  <!-- wrapper end -->
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import TestimonialCard from "@/components/cards/TestimonialCard";

export default {
  /*components: { TestimonialCard },*/
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: "8 - 19 Uhr",
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: "8 - 15 Uhr",
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        }*/,
      ];
    },
  },
  data() {
    return {
      image: require("@/assets/img/zaph/elena-mozhvilo-YzyvxfzddzA-unsplash.jpg"),
      password: null,
      firstname: null,
      email: null,
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.laboratory"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  height: 100%;
  width: 100%;
}
</style>
