<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.communication") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <h3>Aufklärungspflicht und Kommunikationsprobleme</h3>
            <p>
              Wir sind nach § 630e BGB dazu verpflichtet, Sie über sämtliche für
              die Einwilligung zu einer Behandlung wesentlichen Umstände
              aufzuklären.
            </p>

            <p>
              Als Patient können Sie jedoch nur in etwas einwilligen, was Sie
              auch verstehen.
            </p>
            <p>
              <strong
                >Sollten wir also den Eindruck bekommen, dass Sie uns nicht
                richtig verstehen, müssen wir die Behandlung ablehnen.</strong
              >
            </p>

            <p>
              Einige unserer Mitarbeiter sprechen Englisch, Griechisch,
              Russisch, Arabisch und Chinesisch. Sollte Sie nur eine dieser
              Sprachen als sichere Sprache beherrschen, aber kein Deutsch,
              stellen Sie daher bei der Terminvereinbarung sicher, dass ein
              entsprechender Mitarbeiter von uns während Ihres Termins anwesend
              ist.
            </p>
            <p>
              In allen anderen Fällen
              <strong>bringen Sie bitte einen Dolmetscher mit</strong>, der für
              Sie übersetzen kann.
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <h3>
              Our obligation to provide information vs. communication problems
            </h3>
            <p>
              According to Section 630e of the German Civil Code (BGB), we are
              obliged to inform you about all circumstances that are essential
              for consenting to treatment.
            </p>

            <p>
              As a patient, however, you can only consent to something that you
              understand.
            </p>
            <p>
              <strong
                >So if we get the impression that you do not understand us
                correctly, we have to refuse the treatment.</strong
              >
            </p>

            <p>
              Some of our staff speak English, Greek, Russian, Arabic and
              Chinese. If you can only speak one of these languages as a safe
              language, but not German, make sure when making an appointment
              that a suitable employee of ours is present during your
              appointment.
            </p>
            <p>
              In all other cases, please
              <strong
                >bring an interpreter with you who can translate for you</strong
              >.
            </p>
          </div>
          <!-- https://dejure.org/gesetze/BGB/630e.html / https://www.iww.de/zp/web-zwd/informationspflichten-worueber-muss-der-zahnarzt-den-gkv-patienten-bei-der-planung-von-zahnersatz-aufklaeren-f94250 / https://www.iww.de/zp/archiv/aufklaerungspflicht-die-aufklaerungspflicht-des-zahnarztes--das-wichtigste-im-ueberblick-f32246 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/nikolai-chernichenko-LSuIc8Riv9I-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.communication"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
