<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("footer.imprint") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <h3>{{ $t("imprint.contentResponsible") }}</h3>
          <p>
            Zahnarztpraxis Heindel<br />Frau Iro Karouzaki-Heindel<br />Georg-Palitzsch-Straße
            12<br />01239 Dresden<br /><br />{{ $t("common.phone") }}:
            <a href="tel:+493512847014"> 0351 / 284 7014</a><br />E-Mail:
            <a
              href="mailto:iro.karouzaki-heindel@zahnarztpraxis-heindel.de?subject=Impressum"
            >
              iro.karouzaki-heindel@zahnarztpraxis-heindel.de
            </a>
          </p>

          <h4>{{ $t("imprint.doctor") }}</h4>
          <p>Frau Iro Karouzaki-Heindel</p>

          <h4>{{ $t("imprint.jobTitle") }}</h4>
          <p>
            Zahnärztin / Doctor of Dental Surgery (D.D.S.)<br />
            Zahnmedizinische Fakultät der Universität Athen
          </p>

          <h4>{{ $t("imprint.jobTitleOrigin") }}</h4>
          <p>{{ $t("imprint.greece") }}</p>

          <h4>{{ $t("imprint.chamber") }}</h4>
          <p>
            Landeszahnärztekammer Sachsen<br />Schützenhöhe 11<br />01099
            Dresden<br />Fon/Fax: <a href="tel:+4935180660">0351 - 80 660</a>
          </p>

          <h4>{{ $t("imprint.jobRelatedLaws") }}</h4>
          <p>
            sächs. Heilberufekammergesetz<br />
            Berufsordnung<br />
            <a
              href="http://www.zahnaerzte-in-sachsen.de/zahnaerzte/rechtsgrundlagen/berufsrecht"
              >www.zahnaerzte-in-sachsen.de/zahnaerzte/rechtsgrundlagen/berufsrecht</a
            ><br />
            Link:
            <a href="http://www.zahnaerzte-in-sachsen.de"
              >www.zahnaerzte-in-sachsen.de</a
            >
          </p>

          <h3 id="rechtshinweis">{{ $t("imprint.rightsDisclaimer") }}</h3>
          <p>{{ $t("imprint.rightsDisclaimerContent") }}</p>

          <h3 id="haftungshinweis">{{ $t("imprint.liabilityDisclaimer") }}</h3>
          <p>{{ $t("imprint.liabilityDisclaimerContent") }}</p>

          <h3 id="fotos">{{ $t("imprint.photos") }}</h3>
          <p>
            Diverse Hintergrundbilder von
            <a
              href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              >Unsplash</a
            ><!--<br />
                  <a href="https://www.elizavetakovaleva.com/">Elizaveta Kovaleva</a>, Dresden (Teamfotos 2020)-->
          </p>

          <h3 id="icons">{{ $t("imprint.icons") }}</h3>
          <p>
            <a
              href="http://www.iconarchive.com/show/outline-icons-by-iconsmind/Tooth-icon.html"
              >Tooth Icon</a
            >
            (Iconset:
            <a
              href="http://www.iconarchive.com/show/outline-icons-by-iconsmind.html"
              >Line Icons</a
            >) from <a href="http://www.iconsmind.com/">IconsMind</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},

  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/clark-van-der-beken-Tk0B3Dfkf_4-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("footer.imprint"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
