<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-smaller"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.bookAppointment") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container">
          <div id="iframeAlternative2" class="iframeAlternative">
            <a
              href="https://termin.teemer.de/calendar/C81CBD393404FF9F4C31A9C9194E81A6"
              target="zapapp"
              ><md-button class="md-success" style="width: 100%">
                <md-icon>calendar</md-icon> {{ $t("menu.openBookingForm") }}
              </md-button></a
            >

            <hr />
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100">
              <div v-if="$i18n.locale == 'de'">
                <p>
                  Als Alternative zum Buchungsformular rufen Sie uns zur
                  Terminvereinbarung gern direkt an oder schreiben Sie uns eine
                  Nachricht mit möglichen Zeitfenstern, die für Sie passen
                  würden!
                </p>
                <p>
                  Unsere Kolleg:innen an der Rezeption werden dann versuchen,
                  Sie zeitnah zu platzieren und dabei mögliche frei gewordene
                  Lücken nutzen.
                </p>
                <p class="iframeContainer">
                  <strong>Hinweis:</strong> Es kann beim ersten Aufruf ca. 20
                  Sekunden dauern, bis das Formular angezeigt wird. (<a
                    href="https://termin.teemer.de/calendar/C81CBD393404FF9F4C31A9C9194E81A6"
                    target="zapapp"
                    >bei Darstellungsproblemen hier klicken</a
                  >)
                </p>
              </div>
              <div v-if="$i18n.locale == 'en'">
                <p>
                  As an alternative to the booking form, please call us directly
                  to make an appointment or send us a message with possible time
                  frames that would suit you!
                </p>
                <p>
                  Our colleagues at the reception will then try to place you
                  promptly and take advantage of any gaps that may become free.
                </p>
                <p class="iframeContainer">
                  <strong>Notice:</strong> It may take up to 20 seconds for the
                  booking form to load the first time. (<a
                    href="https://termin.teemer.de/calendar/C81CBD393404FF9F4C31A9C9194E81A6"
                    target="zapapp"
                    >in case of rendering problems click here</a
                  >)
                </p>
              </div>
            </div>
            <div
              class="md-layout-item md-size-33 md-small-size-100"
              style="xargin-top: -88px !important"
            >
              <info-areas info-horizontal icon-color="rose" icon="schedule"
                ><h4 slot="title" class="info-title">
                  {{ $t("menu.contactInfo") }}
                </h4>
                <div slot="content" class="description">
                  <p style="font-weight: bold">
                    <a href="tel:+493512847014" style="color: #ff007f"
                      ><i class="material-icons">phone</i> 0351 / 284 7014</a
                    >
                  </p>
                  <p>
                    <a href="https://wa.me/4915678576056" style="color: #000000"
                      ><img
                        src="../assets/img/wa.png"
                        width="24"
                        style="width: 24px"
                      />
                      WhatsApp</a
                    >
                    <a
                      href="https://m.me/zahnarztpraxis.heindel"
                      style="color: #000000"
                      ><img
                        src="../assets/img/fb.png"
                        width="24"
                        style="width: 24px"
                      />
                      Messenger</a
                    >
                  </p>
                  <p>
                    <a
                      href="mailto:info@zahnarztpraxis-heindel.de?subject=Terminanfrage"
                      style="color: #000000"
                      ><i class="material-icons">email</i>
                      info@zahnarztpraxis-heindel.de</a
                    >
                  </p>
                </div>
              </info-areas>
            </div>
          </div>

          <hr />

          <div
            id="iframeContainer"
            className="iframeContainer"
            class="iframeContainer"
            style="
              height: 1600px;
              width: 120%;
              margin-left: -10%;
              overflow: hidden;
              position: relative;
            "
          >
            <iframe
              style="
                height: 1600px;
                top: -285px;
                overflow: hidden;
                position: relative;
              "
              width="100%"
              height="1600"
              scrolling="no"
              src="https://termin.teemer.de/calendar/C81CBD393404FF9F4C31A9C9194E81A6"
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen=""
              title="Online Terminbuchung"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 
<div id="iframeContainer" className="iframeContainer" style="height:100%">
                  <iframe style="marginTop:-170px; height:2000px"
                    width="100%"
                    height="1024"
                    scrolling="yes"
                    src="https://outlook.office365.com/owa/calendar/termine@zahnarztpraxis-heindel.de/bookings/"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="Tesla"
                  />
                </div>
--></template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: this.$t("common.time8to7"),
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: this.$t("common.time8to3"),
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        }*/,
      ];
    },
  },
  data() {
    return {
      image: require("@/assets/img/zaph/eliza-diamond-tT2DSShVDTI-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.bookAppointment"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  @media only screen and (max-device-width: 480px) {
    display: none;
  }
}

.iframeAlternative {
  display: none;
  @media only screen and (max-device-width: 480px) {
    display: block;
  }
}
</style>
