<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size,
    }"
    :data-background-color="backgroundColor"
  >
    <!-- tracking temporarily disabled <cookie-law theme="dark-lime" ref="cookielaw" position="bottom" >

    <div slot-scope="props">

    <div class="md-layout">
      <div class="md-layout-item md-size-100 md-small-size-100" >

        <p style="">
          {{ $t('footer.weUseCookies1') }}
        </p>
        <p style="">
            <i18n path="footer.weUseCookies2" tag="p">
              <template v-slot:action>
                <router-link :to="$i18nRoute({ name: 'dataprotectionpolicy'})" style="color:#fff; text-decoration: underline;">{{ $t('footer.dataprotectionpolicy') }}</router-link>
              </template>
            </i18n>
        </p>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-50" style="">
        <md-button class="skew" @click="disablePlugin(); props.decline()" style="width:100%; white-space: initial;">{{ $t('footer.weUseCookiesNo1') }}<br />{{ $t('footer.weUseCookiesNo2') }}</md-button>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-50" >
        <md-button class="skew md-success" @click="enablePlugin(); props.accept();" style="width:100%; font-weight:bold; white-space: initial;">{{ $t('footer.weUseCookiesYes1') }}<br />{{ $t('footer.weUseCookiesYes2') }}</md-button>
      !-- background-color:yellow!important; color:#000!important; --
      </div>

    </div>

  </div></cookie-law>--><!-- v-if="$route.name !== 'ecommerce-page' && $route.name !== 'presentation' && $route.name !== 'index'"
    -->
    <template v-if="1 != 1">
      <div class="container">
        <nav>
          <ul>
            <li>
              <router-link :to="$i18nRoute({ name: 'impressum' })">{{
                $t("footer.imprint")
              }}</router-link>
            </li>
            <li>
              <router-link :to="$i18nRoute({ name: 'dataprotectionpolicy' })">{{
                $t("footer.dataprotectionpolicy")
              }}</router-link>
            </li>
          </ul>
        </nav>
        <ul class="social-buttons">
          <li>
            <md-button
              href="https://facebook.com/zahnarztpraxis.heindel?ref=website"
              target="_blank"
              class="md-facebook md-just-icon md-simple"
            >
              <i class="fab fa-facebook" :title="$t('footer.followFB')" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://instagram.com/zahnarzt_dresden?ref=website"
              target="_blank"
              class="md-instagram md-just-icon md-simple"
            >
              <i class="fab fa-instagram" :title="$t('footer.followIG')" />
            </md-button>
          </li>
        </ul>
      </div>
    </template>

    <template v-else>
      <div class="container flex-direction-row">
        <router-link :to="$i18nRoute({ name: 'index' })" class="footer-brand">{{
          $t("footer.claim")
        }}</router-link>
        <ul>
          <li>
            <router-link :to="$i18nRoute({ name: 'impressum' })">{{
              $t("footer.imprint")
            }}</router-link>
          </li>
          <li>
            <router-link :to="$i18nRoute({ name: 'dataprotectionpolicy' })">{{
              $t("footer.dataprotectionpolicy")
            }}</router-link>
          </li>
        </ul>

        <ul class="social-buttons">
          <!--<li>
            <md-button              
              class="md-just-icon md-simple"
              v-on:click="settings"
             
            >
             <i class="material-icons" >settings</i><md-tooltip md-direction="top">{{ $t('footer.openSettings')}}</md-tooltip>
            </md-button>
          </li>-->
          <li>
            <md-button
              class="md-just-icon md-simple"
              href="https://zahnarztpraxisheindel.sharepoint.com"
              target="intranet"
            >
              <i class="material-icons">lock</i
              ><md-tooltip md-direction="top">{{
                $t("footer.openIntranet")
              }}</md-tooltip>
            </md-button>
          </li>
          <li>
            <md-button
              href="https://facebook.com/zahnarztpraxis.heindel?ref=website"
              target="_blank"
              class="md-facebook md-just-icon md-simple"
            >
              <i class="fab fa-facebook" /><md-tooltip md-direction="top">{{
                $t("footer.followFB")
              }}</md-tooltip>
            </md-button>
          </li>
          <li>
            <md-button
              href="https://instagram.com/zahnarzt_dresden?ref=website"
              target="_blank"
              class="md-instagram md-just-icon md-simple"
            >
              <i class="fab fa-instagram" /><md-tooltip md-direction="top">{{
                $t("footer.followIG")
              }}</md-tooltip>
            </md-button>
          </li>
        </ul>
      </div>
    </template>
  </footer>
</template>
<script>
//import CookieLaw from 'vue-cookie-law'

export default {
  //components: { CookieLaw },
  props: {
    backgroundColor: String,
    type: String,
    size: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    settings: function (event) {
      this.$refs.cookielaw.isOpen = true;
      return false;
    },
    disablePlugin() {
      removeFacebookPixelCode();
      gaOptout();
      deleteGACookies();
      //deleteAllCookies();
      //location.reload();
    },
    enablePlugin() {
      embedTrackingCode();
      embedFacebookPixelCode();
    },
  },
};
</script>
<style lang="scss" scoped>
.zaphpositive {
  background-color: yellow;
  color: #000;
}
</style>
