<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.feedback") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container">
          <div
            id="iframeContainer"
            className="iframeContainer"
            style="height: 2200px"
          >
            <iframe
              style="margintop: -170px; height: 2200px"
              width="100%"
              height="100%"
              scrolling="no"
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=E_o4K4QsykKzRtwoyCQPCiszGrwdHuNNgLfqx-8nsDBUQ0VaNUs2S1Q3OEVUVjU2UTJQRENVNUFNSS4u&embed=true"
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen=""
              title="Patienenfeedback"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoAreas } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/anton-YTYlNNl7bo0-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.feedback"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  height: 100%;
  width: 100%;
}
</style>
