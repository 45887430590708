// prettier-ignore
<!-- prettier-ignore -->
<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent,
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start" style="min-width: 230px">
        <h3 class="md-title">
          <router-link :to="$i18nRoute({ name: 'index' })"
            ><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACV0lEQVR42rXWW4hNcRTH8TVeSG4p9yeHB7cJQ+FFGHIdkVGSKJdSktukyaUGDTIzTFOEkIbmBUPKLbfyoEyRcmkeXPKCjFxi3oTvaq1T//Nvn85oj1Wfztnn/Pf/t8/e/732KZLcmoAKzEBftOM5buOGv9cajekoRQn64Avuog6PsxMWBZPrxFuwH5fxET19grmYhWJ0wTtc9eAWfMZALMYOHEFtGLACu/yoPkj+GoKNWIN9aEgYMwj3UI3zGtANbzAPT6VjNVx3xmuswq/o+7F+SjMaUIZNmNnBybPVFRdwEY0J3+vpa9CASr9Ilf8YUKgO4Pv/DDiIrxqwQGz1lHZywB3UhxdZg5500uTjcU38ImstRxWm4X3KyQfjPvagKbzRjqMNu1MG7EV/rNeNMGCrp1ekDKgR6wJ1cYDenZOxLmXASbH2cSoOKPPJF6YMuIIzYr0qJ2AczvprUm3DTn9fnT0FCaUrcbV42wkDeog1ut74He20CIcw27dvYbsfbVg63zexpvgzDtB6K9aWXyUclbbhm749R6ytl0TjMmI3WCZMDEsbVzOags9G4TqG4k+wnx6MduCXwdhlWIKl+QI2Y4T4GvbS5TsMG6KxR8Xa9eHgs2NoleA5EQeMFOvj4dHqamj0XxdWOVZK7qrLPlda8wVovRBbrg/FHo9tHvwpGjfAT08/sUUxCacxJhyUFKAPnyl+PnUnvWGKJbmeYS0e4ZzYw76+UEB3/xUaNF9s6VblCdDP9Rl8CSf86NsLBWhN9J1+YKrYX5Kk0r82D9DLr0lLPOAv4hN7sZ6TwOUAAAAASUVORK5CYII="
              style="padding-right: 6px"
              alt="Tooth icon"
            />{{ brand }}</router-link
          >
          <md-tooltip md-direction="bottom">{{ $t("menu.home") }}</md-tooltip>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">people</i>
                        <p>{{ $t("menu.practice") }}</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <router-link :to="$i18nRoute({ name: 'team' })"
                            ><i class="material-icons">group</i
                            >{{ $t("menu.team") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'bookOnline' })"
                            ><i class="material-icons" style="color: #4caf50"
                              >book_online</i
                            >{{ $t("menu.bookOnline") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'location' })"
                            ><i class="material-icons">commute</i
                            >{{ $t("menu.howToReachUs") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            :to="$i18nRoute({ name: 'accessibility' })"
                            ><i class="material-icons">accessible</i
                            >{{ $t("menu.accessibility") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'financing' })"
                            ><i class="material-icons">payment</i
                            >{{ $t("menu.paymentOptions") }}</router-link
                          >
                        </li>

                        <li>
                          <router-link :to="$i18nRoute({ name: 'laboratory' })"
                            ><i class="material-icons">biotech</i
                            >{{ $t("menu.laboratory") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'forms' })"
                            ><i class="material-icons">edit</i
                            >{{ $t("menu.forms") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'joboffers' })"
                            ><i class="material-icons">person_add</i
                            >{{ $t("menu.joboffers") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'ratings' })"
                            ><i class="material-icons">thumb_up</i
                            >{{ $t("menu.recommendations") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'takeover' })"
                            ><i class="material-icons">work</i
                            >{{ $t("menu.takeover") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'faq' })"
                            ><i class="material-icons">contact_support</i
                            >{{ $t("menu.faq") }}</router-link
                          >
                        </li>
                        <li><div class="dropdown-divider" /></li>
                        <li>
                          <router-link :to="$i18nRoute({ name: 'corona' })"
                            ><i class="material-icons" style="color: #f44336"
                              >coronavirus</i
                            >{{ $t("menu.corona") }}</router-link
                          >
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

<!--

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">medical_services</i>
                        <p>{{ $t('menu.services') }}</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">


                        <li
                          v-for="li in linksSections"
                          :key="li.name"
                        >
                          <router-link :to="$i18nRoute({ name: li.name})">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{
                              /*li.name.charAt(0).toUpperCase() + li.name.slice(1)*/
                              $t('menu.'+li.name)
                            }}
                          </router-link>
                        </li>

                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">assignment_ind</i>
                        <p>Zahnmedizin für...</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        
                        <li
                          v-for="li in linksExamples"
                          :key="li.name"
                        >
                          <router-link :to="$i18nRoute({ name: li.name})">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{
                              /*li.name.charAt(0).toUpperCase() + li.name.slice(1)*/
                              $t('menu.'+li.name)
                            }}
                          </router-link>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>


-->

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        class="md-success md-round"
                        data-toggle="dropdown"
                        ><md-icon>phone</md-icon
                        >{{ $t("menu.scheduleAppointment") }}</md-button
                      >

                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a :href="'tel:+493512847014'">
                            <i class="material-icons">phone</i>
                            {{ $t("menu.callNumber") }}
                          </a>
                        </li>

                        <li>
                          <router-link :to="$i18nRoute({ name: 'bookOnline' })"
                            ><i class="material-icons">book_online</i>
                            {{ $t("menu.bookOnline") }}</router-link
                          >
                        </li>

                        <li>
                          <router-link :to="$i18nRoute({ name: 'location' })">
                            <i class="material-icons">directions</i>
                            {{ $t("menu.comeVisit") }}
                          </router-link>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <LocaleSwitcher />
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
import { LocaleSwitcher } from "@/components";

export default {
  components: {
    MobileMenu,
    LocaleSwitcher,
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark",
        ].includes(value);
      },
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
    navbarTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      docs_link:
        "https://demos.creative-tim.com/vue-material-kit-pro/documentation/",
      extraNavClasses: "",
      toggledClass: false,
      brand: "Zahnarztpraxis Heindel",

      linksSections: [
        {
          name: "professionelle-zahnreinigung-pzr-prophylaxe",
          icon: "article",
        },
        {
          name: "zahnfleischbehandlung-parodontitis-therapie",
          icon: "article",
        },
        { name: "wurzelbehandlung", icon: "article" },
        { name: "implantologie", icon: "article" },
        { name: "zahnersatz-kronen-bruecken-prothesen", icon: "article" },
        { name: "kiefergelenkbeschwerden-cmd", icon: "article" },
        { name: "digitale-abformung", icon: "3d_rotation" },
        { name: "hochaesthetische-loesungen", icon: "mood" },
        { name: "vorsorgeuntersuchung-bonusheft", icon: "check_circle" },
        { name: "zahnaufhellung", icon: "brightness_high" },
        { name: "hausbesuch", icon: "home" },
        { name: "profiles", icon: "home" },
        { name: "hausbesuch", icon: "home" },
        { name: "basic", icon: "home" },
        { name: "comments", icon: "home" },
        { name: "tabs", icon: "home" },
      ],
      linksExamples: [
        { name: "zahnmedizin-fuer-kinder", icon: "child_care" },
        { name: "zahnmedizin-fuer-erwachsene", icon: "face" },
        { name: "zahnmedizin-fuer-senioren", icon: "directions_walk" },
        { name: "zahnmedizin-fuer-schwangere", icon: "pregnant_woman" },
        { name: "zahnmedizin-fuer-angstpatienten", icon: "mood_bad" },
        {
          name: "zahnmedizin-fuer-eingeschraenkte-beweglichkeit",
          icon: "accessible",
        },
        {
          name: "zahnmedizin-fuer-schmerzpatienten-notbehandlung",
          icon: "local_hospital",
        },
        {
          name: "zahnmedizin-fuer-kassenpatienten-privatpatienten-selbstzahler",
          icon: "receipt",
        },
      ],
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["index"];
      return excludesdRoutes.every((r) => r !== this.$route.name);
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
  },
};
</script>

<style scoped>
.md-title > a {
  color: #000 !important;
}
</style>
