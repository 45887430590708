<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.howToReachUs") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div v-if="$i18n.locale == 'de'">
                <h3>Lage im Haus</h3>
                <ul>
                  <li>
                    Unsere Praxis befindet sich im 3. OG an der Südseite des
                    Gebäudes (gegenüber von LIDL)
                  </li>
                  <li>
                    Sie erreichen uns über alle Eingänge, Treppen und Aufzüge
                  </li>
                  <li>
                    Orientieren können Sie sich an den Schildern für die Ärzte:
                    Heindel (ehem. Dr. Ittlinger / Dr. Stiller) / Hübler /
                    Hölzer
                  </li>
                  <li>
                    Im Abschnitt der Zahnarztpraxen laufen Sie an der Rezeption
                    von Dr. Hölzer vorbei und biegen nach links ab
                  </li>
                  <li>Sie finden unseren Empfang am Ende des Ganges</li>
                </ul>
              </div>
              <div v-if="$i18n.locale == 'en'">
                <h3>Location in the house</h3>
                <ul>
                  <li>
                    Our practice is located on the 3rd floor on the south side
                    of the building (across from LIDL)
                  </li>
                  <li>
                    You can reach us via all entrances, stairs and elevators
                  </li>
                  <li>
                    You can orientate yourself on the signs for the doctors:
                    Heindel (formerly Dr. Ittlinger / Dr. Stiller) / Hübler /
                    Hölzer
                  </li>
                  <li>
                    In the section of the dental practice you walk past to the
                    reception of Dr. Hölzer and then turn left
                  </li>
                  <li>
                    You will find our reception at the end of the corridor
                  </li>
                </ul>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div v-if="$i18n.locale == 'de'">
                <h3>Mit öffentlichen Verkehrsmitteln</h3>
                <p>
                  Bus Linie 66, Haltestelle Fritz-Meinhard-Straße Tram Linie 1,
                  9, 13, Haltestelle Georg-Palitzsch-Straße
                </p>
                <p>
                  Bitte fahren Sie mit den Straßenbahnlinien 1, 9 oder 13 bis
                  zur Georg-Palitzsch-Straße. Von hier aus sind es noch 350m
                  Fußweg bis zu unserer Praxis.
                </p>
                <p>
                  Verbindungsauskunft auf der DVB Website abrufen:<br />
                  <a
                    href="https://www.dvb.de/de-de/fahrplan/verbindungsauskunft?destinationid=streetID:1500000556:12:14612000:-1:Georg-Palitzsch-Stra%C3%9Fe:Dresden:Georg-Palitzsch-Stra%C3%9Fe::Georg-Palitzsch-Stra%C3%9Fe:01239:ANY:DIVA_SINGLEHOUSE:1535434:5377985:MRCV:VVO"
                    ><img
                      src="../assets/img/dvb.png"
                      alt="Banner für DVB AG Verbindungsauskunft, Quelle: https://www.dvb.de/de-de/fahrplan/fahrplan-fuer-ihre-website"
                  /></a>
                </p>
              </div>
              <div v-if="$i18n.locale == 'en'">
                <h3>Via public transport</h3>
                <!--<div
                  style="
                    position: relative;
                    paddingbottom: 300px;
                    height: 0;
                    width: 235px;
                  "
                >
                  <iframe
                    src="https://www.dvb.de/en-gb/hl?id=0000streetID%3A1500000656%3A12%3A14612000%3A-1%3AGeorg-Palitzsch-Stra%C3%9Fe%3ADresden%3AGeorg-Palitzsch-Stra%C3%9Fe%3A%3AGeorg-Palitzsch-Stra%C3%9Fe%3A01239%3AANY%3ADIVA_SINGLEHOUSE%3A1535434%3A5377985%3AMRCV%3AVVO"
                    frameborder="0"
                    seamless="seamless"
                    scrolling="no"
                    allowfullscreen
                    style="
                      border: none;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      minwidth: 160px;
                      minheight: 200px;
                    "
                  ></iframe>
                </div>-->
                <p>
                  Bus line 66, stop Fritz-Meinhard-Straße Tram lines 1, 9, 13,
                  stop Georg-Palitzsch-Straße
                </p>
                <p>
                  Please take tram lines 1, 9 or 13 to Georg-Palitzsch-Straße.
                  From here it is a 350m walk to our practice.
                </p>
                <p>
                  Open journey planner on DVB website:<br />
                  <a
                    href="https://www.dvb.de/de-de/fahrplan/verbindungsauskunft?destinationid=streetID:1500000556:12:14612000:-1:Georg-Palitzsch-Stra%C3%9Fe:Dresden:Georg-Palitzsch-Stra%C3%9Fe::Georg-Palitzsch-Stra%C3%9Fe:01239:ANY:DIVA_SINGLEHOUSE:1535434:5377985:MRCV:VVO"
                    ><img
                      src="../assets/img/dvb.png"
                      alt="
                    Banner for DVB AG journey planner, source: https://www.dvb.de/de-de/fahrplan/fahrplan-fuer-ihre-website"
                  /></a>
                </p>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div v-if="$i18n.locale == 'de'">
                <h3>Mit dem PKW</h3>
                <p>
                  Anschrift für Navigationsgerät: Georg-Palitzsch-Straße 12,
                  01239 Dresden
                </p>
                <p>
                  Die Praxis ist nahe der Kreuzung Dohnaer Str. /
                  Fritz-Meinhard-Straße gelegen. (Zur Orientierung:
                  Mercedes-Benz STERNAUTO - Dresden, SELGROS Cash & Carry,
                  CleanCar)
                </p>
                <p>
                  In unmittelbarer Nähe befinden sich außerdem der Schlosspark,
                  ein LIDL Supermarkt und die AWO Sachsen.
                </p>
                <h3>Parken</h3>
                <p>
                  Es befinden sich ausgewiesene Parkplätze direkt vor dem
                  Eingang (2h mit Parkscheibe) und weitere Parkplätze entlang
                  der Straße.
                </p>
              </div>
              <div v-if="$i18n.locale == 'en'">
                <h3>By car</h3>
                <p>
                  Address for navigation device: Georg-Palitzsch-Straße 12,
                  01239 Dresden
                </p>
                <p>
                  The practice is located near the intersection of Dohnaer Str.
                  and Fritz-Meinhard-Strasse. (For orientation: Mercedes-Benz
                  STERNAUTO - Dresden, SELGROS Cash &amp; Carry, CleanCar)
                </p>
                <p>
                  The castle park, a LIDL supermarket and the AWO Sachsen are in
                  the immediate vicinity.
                </p>
                <h3>Parking</h3>
                <p>
                  There are designated parking spaces directly in front of the
                  entrance (2 hours with parking disc) and further parking
                  spaces along the street.
                </p>
              </div>
            </div>

            <div class="md-layout-item md-size-100 md-small-size-100">
              <h3>{{ $t("common.routeplanner") }}</h3>
              <ul>
                <li>
                  <a
                    href="https://www.google.com/maps/dir/Zahnarztpraxis+%CE%97eindel,+Georg-Palitzsch-Stra%C3%9Fe+12,+01239+Dresden/@51.0040329,13.7928608,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4709c78d2744da33:0xca5841424813f33e!2m2!1d13.7928608!2d51.0040329"
                    target="_blank"
                    >Routenbeschreibung mit Google Maps abrufen
                  </a>
                </li>
              </ul>
              <!--
              <div
                id="iframeContainer"
                className="iframeContainer"
                style="height: 600px; margin-bottom: 60px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10042.571901982285!2d13.79337672468049!3d51.00426933211222!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xca5841424813f33e!2sZahnarztpraxis%20%CE%97eindel!5e0!3m2!1sde!2sde!4v1635866672001!5m2!1sde!2sde"
                  width="100%"
                  height="600"
                  style="border: 0; height: 600px"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 
<div id="iframeContainer" className="iframeContainer" style="height:100%">
                  <iframe style="marginTop:-170px; height:2000px"
                    width="100%"
                    height="1024"
                    scrolling="yes"
                    src="https://outlook.office365.com/owa/calendar/termine@zahnarztpraxis-heindel.de/bookings/"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="Tesla"
                  />
                </div>
--></template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: "8 - 19 Uhr",
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: "8 - 15 Uhr",
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        }*/,
      ];
    },
  },
  data() {
    return {
      image: require("@/assets/img/zaph/sylwia-bartyzel-D2K1UZr4vxk-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.howToReachUs"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  height: 100%;
  width: 100%;
}
</style>
