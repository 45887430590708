<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title" itemprop="name">
              {{ $t("menu.corona") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <meta itemprop="expires" content="2022-01-01T08:00" />
          <meta
            itemprop="diseasePreventionInfo"
            content="https://www.coronavirus.sachsen.de/"
          />
          <meta
            itemprop="category"
            content="https://www.wikidata.org/wiki/Q81068910"
          />

          <div v-if="$i18n.locale == 'de'">
            <div itemprop="text">
              <h3>Höchste Standards bei Hygiene, Qualität und Sicherheit</h3>

              <p>
                Wir haben uns im Praxisbetrieb auf das Leben mit dem Coronavirus
                eingestellt.
              </p>
              <p>
                Entsprechende Schutzausrüstung ist vorhanden und
                <strong
                  >alle Behandlungen und Vorsorgeuntersuchungen können
                  durchgeführt werden</strong
                >.
              </p>
              <!-- <p>
                Gemäß § 28b Abs. 1 des
                <a
                  itemprop="quarantineGuidelines"
                  content="https://www.gesetze-im-internet.de/ifsg/index.html"
                  href="https://www.gesetze-im-internet.de/ifsg/index.html"
                  >Infektionsschutzgesetz - IfSG</a
                >
                dürfen Sie derzeit unsere Praxis nur betreten, wenn Sie eine
                <i
                  class="material-icons"
                  style="color: #ff007f; vertical-align: middle"
                  >masks</i
                >Atemschutzmaske (FFP2 oder vergleichbar) tragen.
              </p> -->

              <p>
                <strong
                  >Bitte suchen Sie uns nicht persönlich auf, wenn Sie
                  Krankheitssymptome aufweisen oder wissentlich Kontakt mit
                  einer auf den Coronavirus positiv getesteten Person hatten.
                </strong>
              </p>
              <p>
                Die Zahnarztpraxis Heindel ist sich Ihrer Verantwortung bewusst
                und erfüllt höchste Hygienestandards nach den Richtlinien des
                Robert Koch Institutes (RKI) und der Bundeszahnärztekammer -
                denn die Gesundheit unserer Patienten und Mitarbeiter ist unsere
                oberste Priorität.
              </p>

              <h4>Gut zu wissen:</h4>
              <div class="blockquote undefined">
                <p>
                  "Das zahnärztliche Team unterliegt in Deutschland strengen
                  Hygienevorschriften, die zu einem entsprechend hohen
                  Schutzniveau in den Praxen beitragen. Diese
                  Hygienevorschriften gehen davon aus, dass regelmäßig
                  potentiell infektiöse Patienten (z.B. bei Masern, Hepatitis
                  oder HIV) zur Behandlung in die Zahnarztpraxen kommen. Die
                  Vorschriften betreffen alle in der Praxis Beschäftigten
                  gleichermaßen.
                </p>
                <p>
                  Die Auswertungen aus Deutschland, aber auch Norditalien und
                  China zeigen, dass in der Zahnmedizin im Vergleich zur Medizin
                  gar keine oder kaum Infektionen bei zahnmedizinischem Personal
                  stattgefunden haben. Selbst, als die Pandemie noch nicht
                  bekannt war, hat anscheinend die klassische PSA (persönliche
                  Schutzausrüstung) der Zahnmedizin in diesen Ländern
                  Ansteckungen verhindert. Die pandemiebedingte Schließung von
                  Zahnarztpraxen ist aus infektionshygienischer Sicht
                  unbegründet und kann für Patienten zu negativen
                  gesundheitlichen Folgen durch die Verschleppung und das
                  Nichterkennen von Erkrankungen, auch im Interesse der
                  Allgemeingesundheit, führen.
                </p>
                <p>
                  Insbesondere die Behandlung von Parodontalerkrankungen darf
                  nicht vernachlässigt werden. Diese führen zu einer starken
                  Reduktion von Entzündungen im Bereich der Mundhöhle. Deswegen
                  ist die Durchführung der Parodontitisbehandlung, gerade in
                  diesen Pandemiezeiten, wichtig."
                </p>
                <small
                  ><a
                    href="https://www.zahnaerzte-in-sachsen.de/presse/pressemitteilungen/"
                    >Stellungnahme der LZKS und KZVS zur aktuellen
                    Corona-Pandemie (30.10.2020)</a
                  ></small
                >
              </div>
              <small>
                <p itemprop="datePosted" content="2020-07-01T08:00">
                  Veröffentlicht am 01.07.2020
                </p>
                <p itemprop="dateUpdated" content="2023-04-15T08:00">
                  Zuletzt aktualisiert am 15.04.2023
                </p>
              </small>
            </div>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <div itemprop="text">
              <h3>Highest standards in hygiene, quality and safety</h3>

              <p>
                Our practice is prepared for life in times of the corona virus.
              </p>
              <p>
                Appropriate protective equipment is available and
                <strong
                  >all treatments and preventive examinations can be carried
                  out</strong
                >.
              </p>
              <!-- <p>
                In accordance with Section 28b (1) of the current
                <a
                  itemprop="quarantineGuidelines"
                  content="https://www.gesetze-im-internet.de/ifsg/index.html"
                  href="https://www.gesetze-im-internet.de/ifsg/index.html"
                  >Infection Protection Act - IfSG</a
                >
                it is mandatory that you wear a
                <i
                  class="material-icons"
                  style="color: #ff007f; vertical-align: middle"
                  >masks</i
                >respiratory mask (FFP2 or comparable) when visiting our
                practice.
              </p> -->
              <p>
                <strong
                  >Please do not visit us personally if you have symptoms of
                  illness or knowingly had contact with someone who tested
                  positive for the coronavirus.</strong
                >
              </p>
              <p>
                The Heindel dental practice is aware of our responsibility and
                meets the highest hygiene standards according to the guidelines
                of the Robert Koch Institute (RKI) and the German Dental
                Association - because the health of our patients and employees
                is our top priority.
              </p>

              <h4>Good to know:</h4>
              <div class="blockquote undefined">
                <p>
                  "The dental team in Germany is subject to strict hygiene
                  regulations, which contribute to a correspondingly high level
                  of protection in the practices. These hygiene regulations
                  assume that potentially infectious patients (e.g. measles,
                  hepatitis or HIV) regularly come to the dental practice for
                  treatment. The regulations apply equally to all those employed
                  in practice.
                </p>
                <p>
                  The evaluations from Germany, but also northern Italy and
                  China show that in dentistry, compared to medicine, there were
                  no or hardly any infections in dental staff. Even when the
                  pandemic was not yet known, the classic PPE (personal
                  protective equipment) used in dentistry in these countries
                  apparently prevented infection. The closure of dental
                  practices due to a pandemic is unfounded from an infection
                  hygiene point of view and can lead to negative health
                  consequences for patients due to the spread and the failure to
                  recognize diseases, also in the interest of general health.
                </p>
                <p>
                  In particular, the treatment of periodontal diseases should
                  not be neglected. These lead to a strong reduction in
                  inflammation in the oral cavity. That is why it is important
                  to carry out periodontal treatment, especially in these
                  pandemic times."
                </p>
                <small
                  ><a
                    href="https://www.zahnaerzte-in-sachsen.de/presse/pressemitteilungen/"
                    >Statement by the LZKS and KZVS on the current corona
                    pandemic (30.10.2020)</a
                  ></small
                >
              </div>
              <small>
                <p itemprop="datePosted" content="2020-07-01T08:00">
                  Date posted: 2020-07-01
                </p>
                <p itemprop="dateUpdated" content="2023-04-15T08:00">
                  Date updated: 2023-04-15
                </p>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      image: require("@/assets/img/zaph/cdc-k0KRNtqcjfw-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.corona"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
      headAttrs: {
        itemscope: true,
        itemprop: "SpecialAnnouncement",
        itemtype: "https://schema.org/SpecialAnnouncement",
      },
    };
  },
};
</script>
