<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.paymentOptions") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <h2 className="{classes.sectionTitle}">
            {{ $t("common.paymentOptions") }}
          </h2>

          <h3>{{ $t("common.cardPayment") }}</h3>
          <div v-if="$i18n.locale == 'de'">
            <p>
              Wir akzeptieren sämtliche EC- oder Kreditkarten mit einem Logo von
              Visa, VPay, Mastercard, Maestro und American Express sowie
              NFC-Zahlungen per Google Pay und Apple Pay.
            </p>
            <p>
              Den Zahlungsbeleg können Sie dabei wahlweise auch gern per E-Mail
              anstatt auf Papier erhalten.
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              We accept all EC or credit cards with a logo from Visa, VPay,
              Mastercard, Maestro and American Express as well as NFC payments
              via Google Pay and Apple Pay.
            </p>
            <p>
              You can also receive the payment receipt by email instead of on
              paper.
            </p>
          </div>
          <img
            :src="imgAcceptedCards"
            height="103"
            width="278"
            style="height: 103px; width: 278px"
          />

          <h3>{{ $t("common.invoiceAndFinancing") }}</h3>

          <div v-if="$i18n.locale == 'de'">
            <h4>Ratenzahlung beim Zahnarzt in Dresden-Prohlis</h4>
            <p>
              In Zeiten wachsender finanzieller Zwänge nutzen viele unserer
              Patienten gerne die Annehmlichkeiten von Ratenzahlung. Gern bieten
              wir Ihnen deshalb die bequeme Bezahlung per Rechnung und
              Finanzierung an.
            </p>
            <p>
              Wir arbeiten dazu mit unserem Partner DZR (Deutsches
              Zahnärztliches Rechenzentrum) zusammen. So erhalten Sie neben der
              bestmöglichen medizinischen Behandlung auch einen vertrauensvollen
              Service rund um das Thema Abrechnung.
            </p>
            <p>Von folgenden Leistungen können Sie profitieren:</p>
            <ul>
              <li>Teilzahlungswunsch</li>
              <li>Zahlungszielverlängerung</li>
              <li>Erstattungsservice</li>
              <li>Rechnungskopie</li>
              <li>Zahlungsaufstellung</li>
              <li>Ratenstundung / Sonderzahlung</li>
            </ul>
            <p>
              Details zu den attraktiven Teilzahlungs- und Serviceangeboten
              finden Sie unter:
              <a href="https://www.dzr.de/fuer-patienten.html"
                >https://www.dzr.de/fuer-patienten.html</a
              >
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <h4>Installment payment at the dentist in Dresden-Prohlis</h4>
            <p>
              In times of increasing financial constraints, many of our patients
              like to take advantage of the convenience of installment payments.
              We are therefore happy to offer you convenient payment by invoice
              and financing.
            </p>
            <p>
              We work with our partner DZR (German Dental Computing Center). In
              addition to the best possible medical treatment, you also receive
              trustworthy service on all aspects of billing.
            </p>
            <p>You can benefit from the following services:</p>
            <ul>
              <li>Partial payment request</li>
              <li>Payment term extension</li>
              <li>Refund service</li>
              <li>Copy of invoice</li>
              <li>Payment schedule</li>
              <li>Deferred payment / special payment</li>
            </ul>
            <p>
              Details on the attractive installment payments and service offers
              can be found at:
              <a href="https://www.dzr.de/fuer-patienten.html"
                >https://www.dzr.de/fuer-patienten.html</a
              >
            </p>
          </div>
          <a href="https://www.dzr.de/fuer-patienten.html"
            ><img height="50" :src="dzr" style="height: 50px"
          /></a>
        </div>
      </div>
    </div>
  </div>
  <!-- 
<div id="iframeContainer" className="iframeContainer" style="height:100%">
                  <iframe style="marginTop:-170px; height:2000px"
                    width="100%"
                    height="1024"
                    scrolling="yes"
                    src="https://outlook.office365.com/owa/calendar/termine@zahnarztpraxis-heindel.de/bookings/"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="Tesla"
                  />
                </div>
--></template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: "8 - 19 Uhr",
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: "8 - 15 Uhr",
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        }*/,
      ];
    },
  },
  data() {
    return {
      imgAcceptedCards: require("@/assets/img/zaph/Accepted cards_ 3G.png"),
      dzr: require("@/assets/img/zaph/DZRechenzentrum-Logo_2016_c100m65k7.png"),
      image: require("@/assets/img/zaph/cardmapr-XH2JFgT4Abc-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.financing"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  height: 100%;
  width: 100%;
}
</style>
