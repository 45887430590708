import Vue from "vue";
import Router from "vue-router";
import { Trans } from "@/plugins/translation";

//  Main Pages

// PRAXIS
import Index from "./views/Index.vue";
import Impressum from "./views/Impressum.vue";
import Owner from "./views/owner.vue";
import DataProtectionPolicy from "./views/DataProtectionPolicy.vue";
import Corona from "./views/corona.vue";
import Location from "./views/location.vue";
import Accessibility from "./views/accessibility.vue";
import Financing from "./views/financing.vue";
import Laboratory from "./views/laboratory.vue";
import Forms from "./views/forms.vue";
import Preparation from "./views/preparation.vue";
import Communication from "./views/communication.vue";
import Ratings from "./views/ratings.vue";

import Takeover from "./views/takeover.vue";
import Team from "./views/team.vue";
import Joboffers from "./views/joboffers.vue";
import FAQ from "./views/faq.vue";
import BookAppointment from "./views/bookAppointment.vue";
import Feedback from "./views/feedback.vue";

import PZR from "./views/professionelle-zahnreinigung-pzr.vue";
import PARO from "./views/components/JavascriptComponentsSection.vue";
import CARDS from "./views/components/CardsSection.vue";
import BASIC from "./views/components/BasicElementsSection.vue";
import COMMENTS from "./views/components/CommentsSection.vue";
import TABS from "./views/components/TabsSection.vue";

//  Main Examples
import Error from "./views/Error.vue";

import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import Layout from "./layout/Layout.vue";
import VueMeta from "vue-meta";

Vue.use(Router);
Vue.use(VueMeta);

//const regexp = Trans.supportedLocales.join('|')   // You may want to filter out 'en' first.

export default new Router({
  mode: "history",
  routes: [
    {
      // Redirect user to supported lang version.
      path: "*",
      redirect() {
        return Trans.defaultLocale;
      },
    },
    {
      path: "/impressum",
      redirect: "/de/impressum",
    },
    {
      path: "/team",
      redirect: "/de/praxis/team",
    },
    {
      path: "/datenschutz",
      redirect: "/de/datenschutz",
    },
    {
      path: "/corona-virus-covid-19",
      redirect: "/de/praxis/corona-virus-covid-19",
    },
    {
      path: "/anfahrt-lageplan",
      redirect: "/de/praxis/anfahrt-lageplan",
    },
    {
      path: "/sprechzeiten",
      redirect: "/de/praxis/termin-buchen",
    },
    {
      path: "/termin",
      redirect: "/de/praxis/termin-buchen",
    },
    {
      path: "/termine",
      redirect: "/de/praxis/termin-buchen",
    },
    {
      path: "/termin-buchen",
      redirect: "/de/praxis/termin-buchen",
    },
    {
      path: "/barrierefreiheit",
      redirect: "/de/praxis/barrierefreiheit",
    },
    {
      path: "/zahlungsmoeglichkeiten",
      redirect: "/de/praxis/zahlungsmoeglichkeiten-finanzierung",
    },
    {
      path: "/finanzierung",
      redirect: "/de/praxis/zahlungsmoeglichkeiten-finanzierung",
    },
    {
      path: "/bewertungen",
      redirect: "/de/praxis/bewertungen",
    },
    {
      path: "/feedback",
      redirect: "/de/praxis/patientenfeedback",
    },
    {
      path: "/patientenfeedback",
      redirect: "/de/praxis/patientenfeedback",
    },
    {
      path: "/praxis/uebernahme-ittlinger-stiller-gesundheitszentrum-dresden-prohlis",
      redirect:
        "/de/praxis/uebernahme-ittlinger-stiller-gesundheitszentrum-dresden-prohlis",
    },

    {
      /*path: `/:locale(${regexp})?`,*/
      path: `/:locale`,
      component: Layout,
      beforeEnter: Trans.routeMiddleware,
      children: [
        {
          path: "",
          name: "index",
          components: {
            default: Index,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
        {
          path: "praxis",
          component: Layout,
          children: [
            {
              path: "team",
              name: "team",
              components: {
                default: Team,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "vorbereitung-auf-ihren-termin",
              name: "preparation",
              components: {
                default: Preparation,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "kommunikation",
              name: "communication",
              components: {
                default: Communication,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "termin-buchen",
              name: "bookOnline",
              components: {
                default: BookAppointment,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "anfahrt-lageplan",
              name: "location",
              components: {
                default: Location,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "barrierefreiheit",
              name: "zahnmedizin-fuer-eingeschraenkte-beweglichkeit",
              components: {
                default: Accessibility,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "barrierefreiheit",
              name: "accessibility",
              components: {
                default: Accessibility,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "corona-virus-covid-19",
              name: "corona",
              components: {
                default: Corona,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "zahlungsmoeglichkeiten-finanzierung",
              name: "financing",
              components: {
                default: Financing,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "dentallabor",
              name: "laboratory",
              components: {
                default: Laboratory,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "formulare",
              name: "forms",
              components: {
                default: Forms,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "bewertungen",
              name: "ratings",
              components: {
                default: Ratings,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "patientenfeedback",
              name: "feedback",
              components: {
                default: Feedback,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "uebernahme-ittlinger-stiller-gesundheitszentrum-dresden-prohlis",
              name: "takeover",
              components: {
                default: Takeover,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
          ],
        },
        {
          path: "leistungen",
          component: Layout,
          children: [
            {
              path: "professionelle-zahnreinigung-pzr-prophylaxe",
              name: "professionelle-zahnreinigung-pzr-prophylaxe",
              components: {
                default: PZR,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "zahnfleischbehandlung-parodontitis-therapie",
              name: "zahnfleischbehandlung-parodontitis-therapie",
              components: {
                default: PARO,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "hausbesuch",
              name: "hausbesuch",
              components: {
                default: CARDS,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "basic",
              name: "basic",
              components: {
                default: BASIC,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "comments",
              name: "comments",
              components: {
                default: COMMENTS,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
            {
              path: "tabs",
              name: "tabs",
              components: {
                default: TABS,
                header: MainNavbar,
                footer: MainFooter,
              },
              props: {
                header: { colorOnScroll: 0, navbarTransparent: false },
              },
            },
          ],
        },
        {
          path: "datenschutz",
          name: "dataprotectionpolicy",
          components: {
            default: DataProtectionPolicy,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
        {
          path: "impressum",
          name: "impressum",
          components: {
            default: Impressum,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
        {
          path: "inhaberin",
          name: "owner",
          components: {
            default: Owner,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
        {
          path: "stellenangebote",
          name: "joboffers",
          components: {
            default: Joboffers,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
        {
          path: "faq",
          name: "faq",
          components: {
            default: FAQ,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },

        {
          path: "*",
          name: "errorCatchAll",

          components: {
            default: Error,
            header: MainNavbar,
            footer: MainFooter,
          },
          props: {
            header: { colorOnScroll: 0, navbarTransparent: false },
          },
        },
      ],
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
