// prettier-ignore
<!-- prettier-ignore -->
<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.team") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <p>
              Unser Praxisteam kümmert sich mit Leidenschaft zum Beruf um Ihre
              gute Behandlung.
            </p>
            <p>
              Bei uns arbeiten erfahrene Zahnärztinnen, Zahnmedizinische
              Fachangestellte (ZFA),
              <!--Zahnmedizinische Prophylaxeassistenzen (ZMP), Zahnmedizinische
              Verwaltungsassistenzen (ZMV), -->Auszubildende und
              Empfangsmitarbeiterinnen.
            </p>
            <p>
              Ergänzt werden wir durch externe Experten, z.B. bei der
              3D-Modellierung von Zahnersatz für die Keramik-Fräsmaschine und
              Spezialisten für komplexe implantologische Fälle.
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <p>
              Our practice team takes care of your good treatment with passion
              for their profession.
            </p>
            <p>
              We have experienced dentists, dental assistants (ZFA)<!-- , dental
              administrative assistants (ZMV)-->, trainees and receptionists.
            </p>
            <p>
              We are supplemented by external experts, e.g. in the 3D modeling
              of dentures for the ceramic milling machine and specialists for
              complex implantological cases.
            </p>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("common.treaters") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile10"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Iro Karouzaki-Heindel</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Doctor of dental surgery (D.D.S.), Praxisinhaberin
                    </h6>
                    <p>
                      Zahnärztin für allgemeine Zahnmedizin,<br />Prothektik,
                      Endotontologie, Implantologie, Praxisleitung
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Jetzt Termin buchen!</a
                    >
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Doctor of dental surgery (D.D.S.), Practice owner
                    </h6>
                    <p>
                      Dentist for general dentistry,<br />prosthodontics,
                      endotontology, implantology, practice management
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Book appointment now!</a
                    >
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Xiaoyuan Xu</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">doctor medicinae dentariae (dr. med. dent.)</h6>
                    <p>
                      Zahnärztin für allgemeine Zahnmedizin,<br />Prothektik,
                      Endotontologie, Parodontologie
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Jetzt Termin buchen!</a
                    >
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">doctor medicinae dentariae (dr. med. dent.)</h6>
                    <p>
                      Dentist for general dentistry,<br />prosthodontics,
                      endotontology, periodontology
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Book appointment now!</a
                    >
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfileOlschanski"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Anastasia Olschanski</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Zahnärztin
                    </h6>
                    <p>
                      Zahnärztin für allgemeine Zahnmedizin,<br />Prothektik,
                      Endotontologie, Parodontologie
                    </p>
                    <!--<a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Jetzt Termin buchen!</a
                    >-->
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Dentist
                    </h6>
                    <p>
                      Dentist for general dentistry,<br />prosthodontics,
                      endotontology, periodontology
                    </p>
                    <!--<a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Book appointment now!</a
                    >-->
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile14"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Andrea Landsmann</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Zahnmedizinische Fachangestellte (ZFA)
                    </h6>
                    <p>
                      Professionelle Zahnreinigung (PZR) / Prophylaxe,
                      Patientenbetreuung
                    </p>
                    <p style="font-weight: bold; color: #fff">
                      <md-button class="md-default md-round">
                        <router-link
                          :to="
                            $i18nRoute({
                              name: 'professionelle-zahnreinigung-pzr-prophylaxe',
                            })
                          "
                        >
                          <i class="material-icons">biotech</i>
                          <span style="font-weight: bold; color: #fff">
                            Mehr über professionelle Zahnreinigung erfahren
                          </span>
                        </router-link>
                      </md-button>
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Jetzt Termin buchen!</a
                    >
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Dental assitant (ZFA)
                    </h6>
                    <p>Professional dental cleaning, patient care</p>
                    <p style="font-weight: bold; color: #fff">
                      <md-button class="md-default md-round">
                        <router-link
                          :to="
                            $i18nRoute({
                              name: 'professionelle-zahnreinigung-pzr-prophylaxe',
                            })
                          "
                        >
                          <i class="material-icons">biotech</i>
                          <span style="font-weight: bold; color: #fff">
                            Learn more about professional tooth cleaning (PZR)
                          </span>
                        </router-link>
                      </md-button>
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Book appointment now!</a
                    >
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.ghostFemale"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Loreen Gemballa</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Zahnmedizinische Fachangestellte (ZFA)
                    </h6>
                    <p>
                      Professionelle Zahnreinigung (PZR) / Prophylaxe,
                      Patientenbetreuung
                    </p>
                    <p style="font-weight: bold; color: #fff">
                      <md-button class="md-default md-round">
                        <router-link
                          :to="
                            $i18nRoute({
                              name: 'professionelle-zahnreinigung-pzr-prophylaxe',
                            })
                          "
                        >
                          <i class="material-icons">biotech</i>
                          <span style="font-weight: bold; color: #fff">
                            Mehr über professionelle Zahnreinigung erfahren
                          </span>
                        </router-link>
                      </md-button>
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Jetzt Termin buchen!</a
                    >
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Dental assitant (ZFA)
                    </h6>
                    <p>Professional dental cleaning, patient care</p>
                    <p style="font-weight: bold; color: #fff">
                      <md-button class="md-default md-round">
                        <router-link
                          :to="
                            $i18nRoute({
                              name: 'professionelle-zahnreinigung-pzr-prophylaxe',
                            })
                          "
                        >
                          <i class="material-icons">biotech</i>
                          <span style="font-weight: bold; color: #fff">
                            Learn more about professional tooth cleaning (PZR)
                          </span>
                        </router-link>
                      </md-button>
                    </p>
                    <a
                      href="https://www.zahnarztpraxis-heindel.de/de/praxis/termin-buchen"
                      >Book appointment now!</a
                    >
                  </div>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("menu.laboratory") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile6"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Kristin Uhlemann</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Zahntechnikermeisterin
                    </h6>
                    <p>
                      Hochwertiger Zahnersatz und Reparaturen<br />
                      Digitale Abformung, CEREC 3D
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Master dental technician
                    </h6>
                    <p>
                      High-quality dentures and repairs<br />
                      Digital impression, CEREC 3D
                    </p>
                  </div>
                  <p style="font-weight: bold; color: #fff">
                    <md-button class="md-default md-round"
                      ><router-link :to="$i18nRoute({ name: 'laboratory' })"
                        ><i class="material-icons">biotech</i
                        ><span style="font-weight: bold; color: #fff">
                          {{ $t("menu.laboratory-learn-more") }}
                        </span></router-link
                      >
                    </md-button>
                  </p>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.spacer"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Markus Klein</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Zahntechnikermeister
                    </h6>
                    <p>
                      Hochwertiger Zahnersatz und Reparaturen<br />
                      Digitale Abformung, CEREC 3D
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">
                      Master dental technician
                    </h6>
                    <p>
                      High-quality dentures and repairs<br />
                      Digital impression, CEREC 3D
                    </p>
                  </div>
                  <p style="font-weight: bold; color: #fff">
                    <md-button class="md-default md-round"
                      ><router-link :to="$i18nRoute({ name: 'laboratory' })"
                        ><i class="material-icons">biotech</i
                        ><span style="font-weight: bold; color: #fff">
                          {{ $t("menu.laboratory-learn-more") }}
                        </span></router-link
                      >
                    </md-button>
                  </p>
                </template>
              </profile-card>             
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("common.practicemanagement") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile9"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Christian Heindel</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">IT Berater</h6>
                    <p>
                      Praxis IT, Digitalisierung,<br />
                      Unterstützung der Praxisleitung
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">IT Consultant</h6>
                    <p>
                      Practice IT, digitalization<br />
                      Practice management support
                    </p>
                  </div>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("common.reception") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile13"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Stefanie Wiskandt</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Verwaltung</h6>
                    <p>Empfang, Terminmanagement,<br />Verwaltung</p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Administration</h6>
                    <p>
                      Reception, appointment management,<br />administration
                    </p>
                  </div>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("common.assistance") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Elena</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Behandlungsassistenz</h6>
                    <p>
                      Patientenbetreuung, Stuhlassistenz, Instrumentenpflege
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Treatment assistance</h6>
                    <p>Patient care, chair assistance, instrument care</p>
                  </div>
                </template>
              </profile-card>
              <!--<profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile3"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Bedour</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Behandlungsassistenz</h6>
                    <p>
                      Patientenbetreuung, Stuhlassistenz, Instrumentenpflege
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Treatment assistance</h6>
                    <p>Patient care, chair assistance, instrument care</p>
                  </div>
                </template>
              </profile-card>-->
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile8"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Amir</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Zahnmedizinischer Fachangestellter (ZFA) in Ausbildung / 3. Lehrjahr</h6>
                    <p>                      
                      Patientenbetreuung, Stuhlassistenz, Instrumentenpflege
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Dental assistant (ZFA) trainee in 3rd year</h6>
                    <p>Patient care, chair assistance, instrument care</p>
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfileSamira"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Samira</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Zahnmedizinische Fachangestellte (ZFA) in Ausbildung / 2. Lehrjahr</h6>
                    <p>
                      Patientenbetreuung, Stuhlassistenz, Instrumentenpflege
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Apprentice for dental assistant (ZFA) in 2nd year</h6>
                    <p>Patient care, chair assistance, instrument care</p>
                  </div>
                </template>
              </profile-card>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile12"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Zahra</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">Zahnmedizinische Fachangestellte (ZFA) in Ausbildung / 2. Lehrjahr</h6>
                    <p>
                      Patientenbetreuung, Stuhlassistenz, Instrumentenpflege
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">Apprentice for dental assistant (ZFA) in 2nd year</h6>
                    <p>Patient care, chair assistance, instrument care</p>
                  </div>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h3>{{ $t("common.plans") }}</h3>
              <profile-card
                card-plain
                type="horizontal"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="cardProfiles.cardProfile11"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Bianca Klink</h4>
                  <div v-if="$i18n.locale == 'de'">
                    <h6 class="card-category text-muted">
                      Externe Mitarbeiterin
                    </h6>
                    <p>
                      Heil- und Kostenpläne, Verwaltung,<br />Abrechnungsmanagement
                    </p>
                  </div>
                  <div v-if="$i18n.locale == 'en'">
                    <h6 class="card-category text-muted">External employee</h6>
                    <p>
                      Treatment and cost plans, administration,<br />
                      billing management
                    </p>
                  </div>
                </template>
              </profile-card>
            </div>
          </div>
          <hr />
          <!-- 
<h3>Praxisteam</h3>
<p>Hier stellt sich demnächst unser freundliches und erfahrenes Praxisteam vor.</p>
<ul>
</ul>
-->
          <!--
<h3>Überweiserpraxen</h3>
<h4>Kieferorthopädie</h4>
<ul>
  <li><a href="https://www.kfo-praxis-dresden.de/">Dimitra Antoniou Trobisch - Fachzahnärztin für Kieferorthopädie</a></li>
</ul>
<h4>Endodontologie mit Dentalmikroskop</h4>
<ul>
  <li><a href="https://www.zahnarztpraxis-cendelin.de/">Frank Cendelin - Praxis für Zahnheilkunde</a></li>
</ul>

<hr />-->
          <h3>{{ $t("common.externalServiceProviders") }}</h3>
          <h4>{{ $t("common.cerecExperts") }}</h4>
          <ul>
            <li>
              <a href="https://www.dentsplysirona.com/de-de">Dentsply Sirona</a>
              (Bensheim)
            </li>
          </ul>

          <h4>{{ $t("common.signaturesAndBilling") }}</h4>
          <ul>
            <li>
              <a href="https://www.nelly-solutions.com/">Nelly Solutions</a>
              (Berlin)
            </li>
          </ul>

          <h4>{{ $t("common.billingCenter") }}</h4>
          <ul>
            <li>
              <a href="https://www.dzr.de/"
                >DZR Deutsches Zahnärztliches Rechenzentrum</a
              >
              (Stuttgart)
            </li>
          </ul>

          <h4>{{ $t("common.practiceSoftware") }}</h4>
          <ul>
            <li><a href="https://www.teemer.de/">teemer</a> (Hamburg)</li>
          </ul>
          <h4>{{ $t("common.laboratories") }}</h4>
          <ul>
            <li>
              <a href="https://apex-dentallabor.de/" target="APEX"
                >APEX Dentallabor</a
              >
              (Dresden)
            </li>
            <li>
              <a href="https://dental-art-dresden.de/" target="dentalart"
                >Dental Art Dresden</a
              >
            </li>
            <li>
              <a href="https://bionik-dental.de/" target="bionikdental"
                >BIONIK-DENTAL Laboratories</a
              >
              (Berlin)
            </li>
            <li>
              <a href="https://kluge-dentaltechnik.de/" target="klugedental"
                >Dentaltechnik Kluge</a
              >
              (Dresden)
            </li>
            <li>
              <a href="https://variodental.de/" target="variodental"
                >varioDental</a
              >
              (Dresden)
            </li>
            <!-- <li><a href="https://www.k2dental.de/" target="K2">K2 DENTAL</a></li> -->
          </ul>

          <h4>{{ $t("common.plans") }}</h4>
          <ul>
            <li>
              <a
                href="https://dental-abrechnungsoptimierer.de"
                target="dentalabrechnungsoptimierer"
                >Dental AbrechnungsOptimierer</a
              >
              (Worms)
            </li>
          </ul>
          <!-- 
<h4>Kinderzahnheilkunde</h4>
<ul>
  <li>...</li>
</ul>

<h4>Chirurgie</h4>
<ul>
  <li>...</li>
</ul>



<h4>CEREC 3D</h4>
<ul>
  <li>Dentsply Sirona - Professioneller Anwendungssupport</li>
</ul>

<h4>Depots</h4>
<ul>
  <li>HenrySchein</li>
  <li>GEHRL</li>
  <li>PONEß</li>
</ul>

<h4>Labore</h4>
<ul>
  <li></li>
</ul>


<h4>Abrechnugnsassistenz</h4>
<ul>
  <li></li>
</ul>

-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProfileCard,
  },

  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      cardProfiles: {
        /* Elena */
        cardProfile1: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-1_small.jpg"),
        /* Xiaoyuan */
        cardProfile2: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-2_small.jpg"),
        cardProfile5: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-5_small.jpg"),
        /* Kristin U */
        cardProfile6: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-6_small.jpg"),
        /* Amir */
        cardProfile8: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-8_small.jpg"),
        /* Christian */
        cardProfile9: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-9_small.jpg"),
        /* Iro */
        cardProfile10: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-10_small.jpg"),
        /* Bianca */
        cardProfile11: require("@/assets/img/zaph/Dental-bilder-bianca3.jpeg"),
        /* Ghost */
        cardProfile12: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-2_small.jpg"),
        /* Stefanie Wiskandt */
        cardProfile13: require("@/assets/img/zaph/12122022-1_Praxis Heindel_web-3_small.jpg"),
        /* Andrea Landsmann */
        cardProfile14: require("@/assets/img/zaph/12122022-1_Praxis Heindel_web-1_small.jpg"),
        cardProfileElisa: require("@/assets/img/zaph/28042023-1_Zahnarztpraxis Heindel_web-5_small.jpg"),
        /* Samira Haidari */
        cardProfileSamira: require("@/assets/img/zaph/28042023-1_Zahnarztpraxis Heindel_web-4_small.jpg"),
        cardProfileOlschanski: require("@/assets/img/zaph/26012024-1_Zahnarztpraxis Heindel_web-3_small.jpg"),
        spacer: require("@/assets/img/placeholder.jpg"),
        ghostFemale: require("@/assets/img/zaph/08042022-1_Praxis Heindel_web-ghost_small.jpg"),
      },
      image: require("@/assets/img/zaph/zaph_empfang_abends.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.team"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>

<style lang="scss" scoped>
/*.section {
  padding: 0;
}*/

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
