<template>
  <div
    id="vue-material-kit-pro"
    :class="{ 'nav-open': NavbarStore.showNavbar }"
  >
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {
    neutralURL() {
      var myUrl = this.$route.path;
      return myUrl.split("/").slice(-2).join("/");
    },
    deURL() {
      return process.env.VUE_APP_BASEURL + "/de/" + this.neutralURL;
    },
    enURL() {
      return process.env.VUE_APP_BASEURL + "/en/" + this.neutralURL;
    },
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Zahnarzt in Dresden",
      // all titles will be injected into this template
      titleTemplate:
        "%s - Zahnarztpraxis Heindel im Gesundheitszentrum Dresden-Prohlis",

      htmlAttrs: {
        lang: this.$lang,
      },
      /*headAttrs: {
        test: true,     
        testurl: process.env.VUE_APP_BASEURL+this.deURL
      },*/
      /*link: [
          {            
            rel: 'alternate', 
            hreflang: 'de',
            href: this.deURL
          },
          {            
            rel: 'alternate', 
            hreflang: 'en',
            href: this.enURL
          }

      ],
      meta: [
        { hid:'description', vmid:'description', name: 'description', content: this.$t('main.claim') },        
      ]*/
    };
  },
};
</script>
