<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("footer.dataprotectionpolicy") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <p>
            Nachfolgend erhalten Sie Informationen über die Erhebung
            personenbezogener Daten bei Nutzung unserer Internetseite. Zu den
            personenbezogene Daten gehören alle Daten, die auf Sie persönlich
            beziehbar sind (z.B. Name, Adresse, E-Mail-Adressen,
            Nutzerverhalten, IPAdresse).
          </p>

          <h3>
            1. Wer ist in unserer Praxis für den Datenschutz verantwortlich?
          </h3>
          <p>
            In unserer Praxis ist für den Datenschutz verantwortlich und steht
            Ihnen bei Fragen zur Verfügung:
          </p>
          <md-table
            v-model="tableDataDataProtectionPolicy"
            class="table-striped"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="">
                {{ item.key }}
              </md-table-cell>
              <md-table-cell md-label="">
                {{ item.value }}
              </md-table-cell>
            </md-table-row>
          </md-table>

          <h3>2. Welche personenbezogenen Daten erheben wir?</h3>
          <p>
            Wir erheben, speichern, nutzen, übermitteln oder löschen folgende
            personenbezogene Daten:
          </p>
          <ul>
            <li>
              Interessenten unserer Internetseite und Patienten unserer Praxis,
              die natürliche Personen sind
            </li>
            <li>
              Allen anderen natürlichen Personen, die in Kontakt mit unserer
              Praxis stehen (z.B. Bevollmächtigte von Patienten,
              Erziehungsberechtigte von Patienten, Mitarbeiter juristischer
              Personen, Besucher unserer Internetseite)
            </li>
          </ul>
          <p>
            Personenbezogenen Daten von Ihnen werden von uns erhoben, wenn Sie
            mit uns z.B. per E-Mail oder Telefon in Kontakt treten und einen
            Behandlungstermin ausmachen wollen.
          </p>
          <p>Folgende persönliche Daten verarbeiten wir:</p>
          <ul>
            <li>
              Persönliche Angaben (z.B. Vor- und Nachnamen, Adresse,
              Geburtsdatum und -ort, E-Mail-Adresse, Telefonnummer,
              Versicherungsstatus);
            </li>
            <li>
              Daten zu Ihrem Online-Verhalten und -präferenzen (z.B.
              IP-Adressen, eindeutige Zuordnungsmerkmale mobiler Endgeräte,
              Daten zu Ihren Besuchen auf unserer Internetseite, Endgeräte, mit
              denen Sie unsere Internetseite besucht haben) Angaben zu Kindern
              erheben wir nur dann, wenn diese durch die Erziehungsberechtigten
              in unserer Praxis als Patient vorgestellt werden.
            </li>
          </ul>

          <h3>3. Wer erhält ggf. Ihre personenbezogenen Daten übermittelt?</h3>
          <p>
            Falls einzelne Funktionen unserer Internetseite auf beauftragte
            Dienstleister zurückgreifen, informieren wir Sie in Ziffer 4 dieser
            Datenschutzerklärung im Detail über die jeweiligen Vorgänge. Dabei
            nennen wir auch die festgelegten Kriterien der Speicherdauer.
          </p>
          <h4>Nelly</h4>
          <p>
            Außerdem werden die von Ihnen im Rahmen der Patientenaufnahme
            angegebenen Daten an die Nelly Solutions GmbH („Nelly“) übermittelt.
            Nelly wird von uns als Servicedienstleister für digitale Signaturen
            und Datenmanagement- und Archivierungssysteme sowie zur Abrechnung
            von Selbstzahlerleistungen genutzt. Wir haben mit Nelly einen
            Vertrag über die Auftragsdatenverarbeitung nach Art. 28 DSGVO
            abgeschlossen, unter dem Nelly nur nach unserer Weisung Daten
            verarbeitet. Nelly setzt zur Erfüllung der von uns beauftragten
            Verarbeitungsvorgänge wiederum Unterauftragsverarbeiter ein. Bei
            Fragen hierzu können Sie sich jederzeit an info@nelly-solutions.com
            wenden. Weitere Informationen zu der Datenverarbeitung von Nelly
            finden Sie unter
            <a
              href="https://www.getnelly.de/datenschutz/"
              target="nelly_dataprotection"
              >https://www.getnelly.de/datenschutz/</a
            >.
          </p>

          <h3>
            4. Welchem Zweck dienen Ihre Daten und mit welcher Rechtfertigung
            dürfen wir diese nutzen?
          </h3>
          <p>
            Wenn Sie unsere Internetseite ausschließlich dazu nutzen, sich über
            unsere Praxis zu informieren, erheben wir nur die personenbezogenen
            Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie
            unsere Website betrachten möchten, erheben wir die folgenden Daten,
            die für uns technisch erforderlich sind, um Ihnen unsere Website
            anzuzeigen und die Stabilität und Sicherheit zu gewährleisten
            (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>prache und Version der Browsersoftware.</li>
          </ul>
          <h4>Cookies</h4>
          <p>
            Zusätzlich zu diesen Daten werden bei Ihrer Nutzung unserer
            Internetseite auch Cookies auf Ihrem Rechner gespeichert. Bei
            Cookies handelt es sich um kleine Textdateien, die auf Ihrer
            Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert
            werden und durch welche der Stelle, die den Cookie setzt (hier durch
            uns), bestimmte Informationen zufließen. Cookies können keine
            Programme ausführen oder Viren auf Ihren Computer übertragen. Sie
            dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und
            effektiver zu machen.
          </p>
          <p>Einsatz von Cookies:</p>
          <ol style="list-style-type: lower-alpha">
            <li>
              Diese Website nutzt folgende Arten von Cookies, deren Umfang und
              Funktionsweise im Folgenden erläutert werden:
              <ul>
                <li>Transiente Cookies (dazu b)</li>
                <li>Persistente Cookies (dazu c)</li>
              </ul>
            </li>
            <li>
              Transiente Cookies werden automatisiert gelöscht, wenn Sie den
              Browser schließen. Dazu zählen insbesondere die Session-Cookies.
              Diese speichern eine sogenannte Session-ID, mit welcher sich
              verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
              zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden,
              wenn Sie auf unsere Website zurückkehren. Die Session-Cookies
              werden gelöscht, wenn Sie sich ausloggen oder den Browser
              schließen.
            </li>
            <li>
              Persistente Cookies werden automatisiert nach einer vorgegebenen
              Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
              können die Cookies in den Sicherheitseinstellungen Ihres Browsers
              jederzeit löschen.
            </li>
            <li>
              Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
              konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
              allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie
              eventuell nicht alle Funktionen dieser Website nutzen können.
            </li>
          </ol>

          <p>
            Wollen Sie per E-Mail oder über unser Kontaktformular einen
            Behandlungstermin vereinbaren oder eine Frage an uns richten, werden
            die von Ihnen mitgeteilten Daten (Ihre E-MailAdresse, ggf. Ihr Name
            und Ihre Telefonnummer) von uns gespeichert, um Ihnen einen
            Behandlungstermin zuweisen oder die Anfrage beantworten zu können.
            Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem
            die Speicherung nicht mehr erforderlich ist, oder schränken die
            Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen
            (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO).
          </p>

          <!-- tracking temporarily disabled 
<h4>Google Analytics</h4>
<p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer
gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. 

Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse
von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies
durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung
der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
indem sie das unter dem folgenden Link verfügbare Browser-Plug-In herunterladen und installieren:<br />
<a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
</p>

<h4>Facebook Pixel, Facebook Custom Audiences und Facebook-Conversion</h4>
<p>Innerhalb unseres Onlineangebotes wird das sog. "Facebook-Pixel" des sozialen Netzwerkes Facebook, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"), eingesetzt. Mit Hilfe des Facebook-Pixels ist es Facebook einerseits möglich, Sie als Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences"). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir weiter die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. „Conversion").</p>
<ul>
  <li>Datenschutzerklärung von Facebook: Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer: <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a></li>
  <li>EU-US-Privacy-Shield: Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten: <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a></li>
  <li>Grundlage: Die Nutzung der Facebook Social Plugins erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. </li>
  <li>Widerspruch gegen Datenerfassung: Wenn Sie Facebook-Mitglied sind und nicht möchten, dass Facebook über unser Onlineangebot Daten über Sie sammelt und sie mit bei Facebook gespeicherten Mitgliedsdaten verknüpft, müssen Sie sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen und die Cookies löschen. Weitere Einstellungen und Widersprüche zur Nutzung von Daten für Werbezwecke sind innerhalb der Facebook-Profileinstellungen möglich (<a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>) oder über die US-amerikanische Seite aboutads.info (<a href="http://optout.aboutads.info/?c=2#!/">http://optout.aboutads.info/?c=2#!/</a>) oder die EU-Seite youronlinechoices.com (<a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>). Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.</li>
</ul>
-->

          <h3>
            5. Welche Rechte haben Sie im Zusammenhang mit dem Datenschutz?
          </h3>
          <p>
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
            betreffenden personenbezogenen Daten:
          </p>
          <ul>
            <li>Recht auf Auskunft,</li>
            <li>Recht auf Berichtigung oder Löschung,</li>
            <li>Recht auf Einschränkung der Verarbeitung,</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
            <li>Recht auf Datenübertragbarkeit.</li>
          </ul>

          <h3>6. Wie können Sie sich ggf. beschweren?</h3>
          <p>
            Sie haben die Möglichkeit, sich mit einer Beschwerde im Zusammenhang
            mit der Datenverarbeitung an die für uns zuständige
            Datenschutzaufsicht zu wenden:
          </p>
          <p>
            Der Sächsische Datenschutzbeauftragte<br />
            Devrientstraße 1<br />
            01067 Dresden
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/zaph/clark-van-der-beken-Tk0B3Dfkf_4-unsplash.jpg"),
      tableDataDataProtectionPolicy: [
        {
          id: 1,
          key: "Praxisinhaber",
          value: "Frau Iro Karouzaki-Heindel",
        },
        {
          id: 2,
          key: "Praxisadresse",
          value: `Zahnarztpraxis Heindel,
          Georg-Palitzsch-Straße 12,
          01239 Dresden`,
        },
        {
          id: 3,
          key: "Telefon",
          value: "0351 / 284 7014",
        },
        {
          id: 4,
          key: "E-Mail",
          value: "info@zahnarztpraxis-heindel.de",
        },
      ],
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("footer.dataprotectionpolicy"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}

.md-table-head {
  display: none;
}
</style>
