<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              {{ $t("menu.accessibility") }}
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-text pb-0">
        <div class="container" style="padding-bottom: 60px">
          <div v-if="$i18n.locale == 'de'">
            <h3>
              In unserem Haus sind Patienten mit Mobilitätsproblemen, sowie
              Familien mit Kindern herzlich willkommen.
            </h3>

            <p>
              Das Ärztehaus in Prohlis verfügt auf seiner südlichen Seite über
              eine breite Rampe, um seinen Patienten einen aufwandfreien Zugang
              gewährleisten zu können.
            </p>
            <p>
              Durch die Nutzung von zwei großen Aufzügen (viel Patz für
              Kinderwagen und Rollstuhl), können Sie unsere Räumlichkeiten im 3.
              OG erreichen.
            </p>
            <p>
              Die Hauptpraxistür öffnet sich automatisch, sodass Patienten im
              Rollstuhl oder Eltern mit Kinderwagen mühelos unsere Rezeption
              bzw. unseren Wartebereich erreichen können.
            </p>
            <p>Wir freuen uns, Sie in unserem Haus begrüßen zu dürfen!<br /></p>

            <h3>
              Tipp: Seit Januar 2019 gelten geänderte Regeln zur
              Patientenbeförderung
            </h3>
            <p>
              Informieren Sie sich z.B. bei der Verbraucherzentrale zu Ihren
              Rechten als Patient.
            </p>
          </div>
          <div v-if="$i18n.locale == 'en'">
            <h3>
              Patients with mobility problems and families with children are
              very welcome in our house.
            </h3>

            <p>
              The medical center in Prohlis has a wide ramp on its southern side
              in order to ensure easy access for its patients.
            </p>
            <p>
              By using two large elevators (plenty of space for prams and
              wheelchairs), you can reach our premises on the 3rd floor.
            </p>
            <p>
              The main practice doors open automatically so that patients in
              wheelchairs or parents with strollers can easily reach our
              reception or our waiting area.
            </p>
            <p>We look forward to welcoming you to our house!<br /></p>

            <h3>
              Tip: Since January 2019, the rules for patient transport have
              changed
            </h3>
            <p>
              Find out more about your rights as a patient on the website of the
              consumer advice center.
            </p>
          </div>
          <p>{{ $t("common.learnMore") }}</p>
          <ul>
            <li>
              <a
                href="https://www.verbraucherzentrale.de/wissen/gesundheit-pflege/krankenversicherung/krankentransport-auf-rezept-wann-gesetzliche-krankenkassen-zahlen-33784"
                >Krankentransport auf Rezept: Wann gesetzliche Krankenkassen
                zahlen</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- 
<div id="iframeContainer" className="iframeContainer" style="height:100%">
                  <iframe style="marginTop:-170px; height:2000px"
                    width="100%"
                    height="1024"
                    scrolling="yes"
                    src="https://outlook.office365.com/owa/calendar/termine@zahnarztpraxis-heindel.de/bookings/"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="Tesla"
                  />
                </div>
--></template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  computed: {
    tableDataOpeningHours() {
      return [
        {
          id: 1,
          day: this.$t("common.monday"),
          slot: "8 - 19 Uhr",
        },
        /*{
          id: 2,
          day: this.$t('common.tuesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 3,
          day: this.$t('common.wednesday'),
          slot: "08:00 bis 19:00 Uhr",
        },
        {
          id: 4,
          day: this.$t('common.thursday'),
          slot: "08:00 bis 19:00 Uhr",
        },*/
        {
          id: 5,
          day: this.$t("common.friday"),
          slot: "8 - 15 Uhr",
        } /*,
        {
          id: 6,
          day: "Samstag",
          slot: "geschlossen",
        },
        {
          id: 7,
          day: "Sonntag",
          slot: "geschlossen",
        }*/,
      ];
    },
  },
  data() {
    return {
      image: require("@/assets/img/zaph/elevate-vofmJUVScDE-unsplash.jpg"),
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("menu.accessibility"),
      // all titles will be injected into this template
      //titleTemplate: '%s | My Awesome Webapp'
    };
  },
};
</script>
<style lang="scss" scoped>
.iframeContainer {
  height: 100%;
  width: 100%;
}
</style>
