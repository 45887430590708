import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/locales/de.json";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  //locale: 'en',
  locale: process.env.VUE_APP_I18N_LOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages: { de },
});
