import { i18n } from "@/plugins/i18n";
import axios from "axios";

const Trans = {
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE;
  },
  get supportedLocales() {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  },
  get currentLocale() {
    return i18n.locale;
  },
  set currentLocale(locale) {
    i18n.locale = locale;
  },
  changeLocale(locale) {
    if (!Trans.isLocaleSupported(locale))
      return Promise.reject(new Error("Locale not supported"));

    if (i18n.locale === locale) return Promise.resolve(locale);

    return Trans.loadLocaleFile(locale).then((msgs) => {
      i18n.setLocaleMessage(locale, msgs.default || msgs);
      return Trans.setI18nLocaleInServices(locale);
    });
  },
  isLocaleSupported(locale) {
    return Trans.supportedLocales.includes(locale);
  },
  loadLocaleFile(locale) {
    return import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.json`
    );
  },
  /*setI18nLocaleInServices(locale) {
        Trans.currentLocale = locale
        document.querySelector('html').setAttribute('lang',locale)
        return locale
    },*/
  setI18nLocaleInServices(locale) {
    Trans.currentLocale = locale;
    axios.defaults.headers.common["Accept-Language"] = locale; // <--------------- Now the application will try to use the language preferred by the current visitor.
    document.querySelector("html").setAttribute("lang", locale);
    return locale;
  },
  /*routeMiddleware(to, from, next) {
        const locale = to.params.locale
        if (!Trans.isLocaleSupported(locale)) return next(Trans.defaultLocale)
        return Trans.changeLocale(locale).then(() => next())
    },*/
  routeMiddleware(to, from, next) {
    const locale = to.params.locale;
    if (!Trans.isLocaleSupported(locale))
      return next(Trans.getUserSupportedLocale()); // <----------- 1 If the requested locale is not supported, we fetch the preferred locale.
    return Trans.changeLocale(locale).then(() => next());
  },
  i18nRoute(to) {
    return {
      ...to,
      params: { locale: this.currentLocale, ...to.params },
    };
  },
  getUserSupportedLocale() {
    const userPreferredLocale = Trans.getUserLocale(); // <------------- 1 We read the preferred locale.

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      // <------------- 2 If the app supports this locale, return it.
      return userPreferredLocale.locale;
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoISO)) {
      // <------------- 3 If the locale is unknown, try to strip the ISO code and check again.
      return userPreferredLocale.localeNoISO;
    }
    return Trans.defaultlocale; // <------------- 4 Finally, if both checks were unsuccessful, return the default locale.
  },
  getUserLocale() {
    const locale =
      window.navigator.language ||
      window.navigator.userLanguage ||
      Trans.defaultLocale; // <----------- 1 Try to read the preferred language. If the preferred language is not set, simply use the default locale.
    return {
      locale: locale, // <-------------- 2 Return full locale (for example, en-us) as the first attribute of the object.
      localeNoISO: locale.split("-")[0], // <------------- 3 Return locale without the ISO part (for example, en) as the second attribute.
    };
  },
};

export { Trans };
